import React,{ useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Petroleumbanner from '../images/mining-banner.webp';
import faq from '../images/frequently-asked-questions-solution-concept.webp';
import w from '../images/servicees2.webp';
import MiningCons from "../images/mining_cons1213.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from '../MetaTags';
import '../Stlye.css';
const Realestateconsulting = () => {
  const [openIndex, setOpenIndex] = useState(null);
      const faqData = [
        {
          question: 'What types of mining projects do you work with?',
          answer: 'We have experience working with a wide range of mining projects, including coal, minerals, metals, and aggregates.',
        },
        {
          question: 'How do you ensure environmental compliance?',
          answer: 'We conduct thorough environmental assessments and work closely with regulatory agencies to ensure compliance with all applicable laws and regulations.',
        },
        {
          question: 'Do you offer training programs for mine safety?',
          answer: 'Absolutely. We provide comprehensive training programs tailored to the specific needs of each client, covering topics such as hazard identification, risk management, and emergency response.',
        },
        {
          question: 'How do you determine property valuation?',
          answer: 'Property valuation is determined based on various factors, including location, size, condition, comparable sales, rental income, and market demand. Our expert appraisers use industry-standard methods to ensure accuracy and reliability.',
        },
        {
          question: 'What sets Smart Resources QLD apart from other consulting firms?',
          answer: 'Our industry experience, personalized approach, and commitment to excellence are what set us apart. We go above and beyond to deliver tangible results for our clients, helping them achieve their goals while upholding the highest standards of safety and environmental stewardship.',
        },
        // Add more FAQ items as needed
      ];
      const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
  return (
    <div>
       <MetaTags
        title="Mining Consulting Service in Queensland, Australia | Smart Resources QLD"
        description="Queensland's mining potential with Smart Resources QLD's expert consulting. Optimize operations, navigate regulations & maximize your project's success."
        keywords="Mining Consulting Service in Queensland, Australia"
        identifyUrl="https://smartresourcesqld.com.au/mining-consulting"
        DCTitle="Mining Consulting Service in Queensland, Australia | Smart Resources QLD"
        DCDescription="Queensland's mining potential with Smart Resources QLD's expert consulting. Optimize operations, navigate regulations & maximize your project's success."
        DCSubject="Mining Consulting Service in Queensland, Australia"
        Canonical="https://smartresourcesqld.com.au/mining-consulting"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Mining Consulting Service in Queensland, Australia | Smart Resources QLD"
        ogDescription="Queensland's mining potential with Smart Resources QLD's expert consulting. Optimize operations, navigate regulations & maximize your project's success."
        ogUrl="https://smartresourcesqld.com.au/mining-consulting"
      />
      <section className="innr_banner" style={{ 
      backgroundImage: `url(${Petroleumbanner})` }}>
        <div className="page_title_innr">
          <div className="page_title">      Mining Consulting  </div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/our-operations">Services</NavLink></li>
            <li>Mining Consulting Services</li>
          </ul>
        </div>
      </section>
      <section className="pricing servinnrbg">
        <div className="servinnng_middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="global_left serv_innr_heading">
                  <h1> Mining Consulting Services </h1>
                  <p>Welcome to Smart Resources QLD, your trusted partner for comprehensive mining consulting services in Queensland. With years of experience and a team of industry experts, we specialize in providing tailored solutions to meet the unique needs of mining projects across the region.<br/><br/>
                  <p style={{ fontWeight: 'bolder', marginBottom: '-20px', }}>Our Expertise</p> <br/>At Smart Resources QLD, we understand the complexities and challenges of the mining industry. Whether you're exploring new opportunities, optimizing existing operations, or navigating regulatory requirements, our team is here to support you every step of the way.<div className="col-lg-6 col-md-6 col-sm-12">
             
              </div></p>
              
                </div>
              </div>
              
            
            </div>
          </div>
        </div>
        <div className="discuss-seo seo_price_bg">
        <div className="container">
      <div className="row discuss-ppc-seo">
        <h2>Ready to Optimize Your Mining Operations?</h2>
        <div className="button-ppc-new">
          <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Get Started</NavLink>
          {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
        </div>
      </div>
    </div>
</div>

        <section className="include_services">
        <div className='include_service_innr'>
        <Container>
        <Row>

        <Col lg="4" md="6" sm="12">
        <div className='include_img'><img src={w} alt=" " className="img-fluid" /></div>
        </Col>

        <Col lg="8" md="6" sm="12">
        <div className='include_text'>
            <h3>Our consulting services cover a wide range of areas, including</h3>
            {/* <img src={line} alt="Your Image" /> */}
            <div className='pf'>
            <ul>
              <li>
              <strong> Exploration and Resource Assessment:  </strong> From initial geological surveys to resource estimation, we help clients identify and evaluate potential mining sites.
              </li>
              <li>
              <strong> Operational Efficiency:  </strong> We work closely with mining companies to optimize processes, improve productivity, and reduce costs without compromising on safety or environmental standards.
              </li>
              <li>
              <strong> Environmental Compliance:</strong> Our team assists clients in navigating complex environmental regulations, conducting environmental impact assessments, and implementing sustainable practices.
              </li>
              <li>
              <strong>Community Engagement:</strong> We understand the importance of building positive relationships with local communities. Our consultants help clients develop effective community engagement strategies to foster trust and collaboration.
              </li>
              <li>
              <strong>Safety and Risk Management:</strong> Safety is paramount in the mining industry. We provide comprehensive safety audits, risk assessments, and training programs to ensure compliance and minimize workplace incidents.
              </li>
            </ul>
            </div>
            </div>
        </Col>
        </Row>

  
</Container>


        </div>
        <div className='include_service_innr'>
          <Container>
            <Row>
            <Col lg="8" md="6" sm="12" className='include_textpartts'>
            <div className='include_text'>
              
                <h3>Why Choose Smart Resources QLD</h3>
                <div className='pf'>
                  <ul>
                    <li>
                      <strong>Industry Experience:</strong>Our team brings a wealth of experience and expertise to every project, ensuring practical and effective solutions.
                    </li>
                    <li>
                      <strong>Tailored Approach:</strong> We understand that every mining project is unique. That's why we take a personalized approach, tailoring our services to meet the specific needs and goals of each client.
                    </li>
                    
                    <li>
                      <strong>Commitment to Excellence:</strong> At Smart Resources QLD, we are committed to delivering excellence in everything we do. From project planning to execution, you can trust us to deliver results that exceed your expectations.
                    </li>
                  
                  </ul>
                  </div>
                  <p>We also provide our Mining Consulting services in <NavLink to="/mining-consulting/cairns" onClick={() => window.scrollTo(0, 0)}>Cairns</NavLink>, <NavLink to="/mining-consulting/rockhampton" onClick={() => window.scrollTo(0, 0)}>Rockhampton</NavLink> and <NavLink to="/mining-consulting/townsville" onClick={() => window.scrollTo(0, 0)}>Townsville</NavLink></p>
                </div>
              </Col>

              <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                <div className='include_img right'><img  src={MiningCons} alt=" "   className='img-fluid'/></div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="faqContainer">
        <Container>
        <Row>
          <Col lg="8" md="6" sm="12" className='accordion_left'>
            <div className="accordion">
            <h1>Frequently Asked Questions</h1>
              {faqData.map((item, index) => (
                <div key={index} className="accordion-item" >
                  <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                    <h2 >{item.question}</h2>
                    {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                  </div>
                  {openIndex === index && <div className="accordion-answer">{item.answer}</div>}
                </div>
              ))}
              </div>
            </Col>
            <Col lg="4" md="6" sm="12" className='accordion_right'>
              <div className="faq_image">
                {/* Place your image here */}
                <div>
              <img
                src={faq}
                className="img-fluid"
                alt=" "
              />
              
            </div>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
        </section>
        
      </section>
    </div>
  );
};

export default Realestateconsulting ;
