import React, { useState } from 'react';
import Petroleumbanner from '../images/fulepost.webp';
import bannerImage from '../images/fulepost1.webp';


import bannerImage1 from '../images/realpost1.webp'; 
import bannerImage2 from '../images/datapost.webp' ;
import bannerImage3 from '../images/mingpost1.webp'; 
import bannerImage4 from '../images/prpost1.webp'; 
import MetaTags from '../MetaTags';
import emailjs from '@emailjs/browser';
import './Blogstyle.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
// import {}
const FuelingPost = () => {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('service_ofw3ypm', 'template_ki9qfe5', e.target, 'II-fnB7G49fYlfyTr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // Reset form fields
        setComment('');
        setName('');
        setEmail('');
        setWebsite('');
    };
    const styles = {
        container: {
         
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Arial, sans-serif',
        },
        banner: {
          position: 'relative',
          backgroundColor: '#333',
          color: '#fff',
          // padding: '20px',
          width: '100%',
          zIndex:'1',
          textAlign: 'center',
          position: 'relative',
        },
        bannerOverlay: {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.52)',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        },
      
      
        bannerBlogHeading: {
          position: 'absolute',
          top:'50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color:'#fff',
          fontSize: '40px',
        },
        bannerImage: {
          // maxWidth: '100%',
          // maxHeight: '300px', // Adjust the height as needed
          width:'100%',
          height:'420px',
          objectFit:'cover',
          objectPosition:'center',
        },
        itemContainer: {
          marginTop:"35px",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          // maxWidth: '100%',
          margin: '0 auto',
        },
        // itemRow: {
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   width: '100%',
        // },
      
        breadCrumb: {
          position: 'absolute',
          top: '60%',
          left:'50%',
          transform: 'translateX(-50%)',
        },
        item: {
          position: 'relative',
          // width: '296px',
          // height: '180px',
          backgroundColor: '#eee',
          border: '1px solid #ccc',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          textDecoration: 'none',
          color: '#333',
          transition: 'transform 0.3s ease',
          overflow: 'hidden', // Hide overflowing content
        },
        itemTitle: {
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '5px',
          opacity: 0,
          transition: 'opacity 0.3s ease',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          textAlign: 'center',
          width: '100%',
          padding: '0 10px',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
        },
        itemDetails: {
          fontSize: '14px',
          opacity: 0,
          transition: 'opacity 0.3s ease',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(calc(-50% - 20px))', // Adjust position to accommodate title
          textAlign: 'center',
          width: '100%',
          padding: '0 10px',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
        },
        itemImage: {
          width: '100%',
          height: '300px',
          transition: 'transform 0.3s ease',
          objectFit: 'cover',
          objectPosition: 'center top',
        },
        // Different styles for item1, item2, item3
        item123: {
          backgroundColor: '#f0f0f0',
          color: '#666',
        },
        // Different styles for item4
        item4: {
          backgroundColor: '#ddd',
          color: '#444',
          marginLeft: "150px"
        },
        // Different styles for item5
        item5: {
          backgroundColor: '#ccc',
          color: '#333',
          marginRight: "150px"
        },
      
      
      
       
      };
    return (
        
    <>
    
    <div style={styles.banner} className='Banner_blog'>
    <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        description="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        identifyUrl="https://smartresourcesqld.com.au/fulepost"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        DCDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        Canonical="https://smartresourcesqld.com.au/fulepost"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        ogDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors"
        ogUrl="https://smartresourcesqld.com.au/fulepost"
      />
        {/* <h1>Website Banner</h1> */}
        <img src={bannerImage} alt="Banner" style={styles.bannerImage} />
        <div style={styles.bannerOverlay}></div>
        <div style={styles.bannerBlogHeading}>Blog</div>
        <ul className="bread_crumb" style={styles.breadCrumb}><li><a href="/">Home</a></li><li>Fuel Post</li></ul>
      </div>
    
    <section className="work_safety abt_work_safety blog_partts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-8">
                        <div className="blog_left_inner">
                            <h1>Wings of Opportunity: The Role of Angel Investors in Fueling Innovation</h1>
                            <div>
                                <figure>
                                    <img src= {Petroleumbanner} />
                                </figure>
                                <div className="commnt_sec">
                                   
                                </div>
                            </div>
                            <div className="blog_para">
                            
                                <div className="blog_innr_para">
                                    <h3>What is an Angel Investor?</h3>
                                    <p>
An angel investor is an individual who provides financial backing to startups or early-stage companies in exchange for convertible debt or ownership equity. Unlike venture capitalists, who typically invest funds from institutional sources, angel investors use their personal wealth to support promising ventures. Beyond capital, they often offer strategic guidance, mentorship, and access to valuable networks, leveraging their expertise and experience to help entrepreneurs navigate the challenges of building a successful business.</p>
                                    <label>Fueling Innovation and Entrepreneurship:</label>
                                    <ul>
                                        <li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Angel investors serve as catalysts for innovation, providing much-needed capital to fuel the growth of emerging technologies, disruptive business models, and groundbreaking ideas. By taking calculated risks on unproven ventures, they enable entrepreneurs to pursue their visions, experiment with new concepts, and push the boundaries of what's possible. From cutting-edge biotech startups to revolutionary fintech platforms, angel investors play a vital role in driving forward-thinking innovation across diverse industries.</li>
                                        <label>Supporting Early-Stage Ventures:</label>
                                        <li><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>
                                        For many startups, securing initial funding is a daunting challenge that can make or break their success. Angel investors fill this crucial funding gap, providing seed capital to help early-stage ventures get off the ground. Whether it's funding product development, hiring key talent, or scaling operations, their investment can be the lifeline that propels a promising idea from concept to reality. By taking a chance on unproven entrepreneurs and untested concepts, angel investors foster a culture of risk-taking and entrepreneurialism that fuels economic growth and prosperity.</li>
                                    
<label >Mentorship and Guidance:</label>
<li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Beyond capital, angel investors offer invaluable mentorship and guidance to the entrepreneurs they support. Drawing on their own experiences as successful business leaders, investors provide strategic advice, industry insights, and practical wisdom to help startups navigate the complexities of building a scalable and sustainable business. From refining business models to fine-tuning go-to-market strategies, their guidance can be instrumental in overcoming obstacles and seizing opportunities for growth.
</li>





<label>Access to Networks and Resources:</label>
<li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>In addition to financial support and mentorship, angel investors provide access to valuable networks and resources that can help startups accelerate their growth trajectory. Whether it's making introductions to potential customers, partners, or investors, investors leverage their extensive networks to open doors and create opportunities for collaboration and expansion. By connecting startups with the right people and resources at the right time, they amplify the impact of their investment and help pave the way for long-term success.

In conclusion, angel investors play a vital role in fueling innovation, supporting entrepreneurship, and driving economic growth. By providing capital, mentorship, and access to networks, they empower startups to pursue their visions, overcome challenges, and realize their full potential. As champions of innovation and drivers of change, angel investors are not only shaping the future of business but also shaping the future of society as a whole.
</li>



 
                                    </ul>
                                   
                                </div>
                                {/* Other content omitted for brevity */}
                            </div>
                            {/* Other content omitted for brevity */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="blog_right_innr">
                        <div className="blog_cat post_cat">
                        <h5>Recent Posts</h5>
                        <ul className="post_img">
                            <li>
                                <figure>
                                    <img src= {bannerImage1} />
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/post" onClick={() => window.scrollTo(0, 0)}>Real Estate Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                               
                               
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src= {bannerImage3}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/miningpost" onClick={() => window.scrollTo(0, 0)}>Mining Post</NavLink></h4>

                                    <div className="posted_on">
                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src={bannerImage4}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>Petroleum Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>

                            <li>
                                <figure>
                                    <img src= {bannerImage2}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/datapost" onClick={() => window.scrollTo(0, 0)}>Data Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>

                            <li>
                                <figure>
                                    <img src= {bannerImage}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/fulepost" onClick={() => window.scrollTo(0, 0)}>Fuel Post</NavLink></h4>

                                    <div className="posted_on">
                                        
                            <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>
                        </ul>
        </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12" md="12">
                    <div className="leave_a_reply">
            <h4>Leave A Reply</h4>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <textarea
                        className="form-control text_partts"
                        placeholder="Comments"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control reply_text text_partts1213"
                        placeholder="Name (required)"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        className="form-control reply_text text_partts1213 "
                        placeholder="Email (required)"
                        name="email"
                        style={{float: 'right'}}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                {/* <div className="form-group">
                    <input
                        type="url"
                        className="form-control reply_text"
                        placeholder="Website"
                        name="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div> */}

                <input
                    name="submit"
                    type="submit"
                    id="submit"
                    className="submit"
                    value="Post Comment"
                />
            </form>
                </div> 
                    </Col>    
                </Row>                
                
            </div>
            
        </section>
    
        
    </>
    );
}

export default FuelingPost;
