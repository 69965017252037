import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner1 from '../src/images/data--banner--02.webp';
import Petroleumbanner from '../src/images/data-banner.webp';
import DataCons1213 from "../src/images/data_cons_1213.webp";
import Data222 from "../src/images/data_222.webp";
import Data223 from "../src/images/data_223.webp";
import Data121345 from "../src/images/data__121345.webp";
import faq1 from '../src/images/faq_banner.webp';
import PetroleumCons from "../src/images/petroleum-cons.webp";
import PetLeft from "../src/images/petroleam_cons.webp";
import PetRight from "../src/images/pet_right_right.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageTownsVille() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why is Townsville a strategic location for petroleum operations?',
            answer: 'Townsville`s proximity to key exploration and production sites and its established infrastructure make it an ideal base for petroleum operations in North Queensland.',
        },
        {
            question: 'How can petroleum consulting services benefit my operations?',
            answer: "Our consulting services provide expert insights, strategic planning, and operational support to optimize your petroleum activities, ensuring efficiency and profitability.",
        },
        {
            question: 'What types of petroleum projects do you support in Townsville?',
            answer: "We support a wide range of projects, including exploration, production, refining, and distribution operations across various petroleum products.",
        },
        {
            question: 'How do you ensure regulatory compliance in petroleum operations?',
            answer: 'Our team stays up-to-date with the latest regulations and works closely with you to ensure that all aspects of your operations comply with local, state, and federal laws.',
        },
        {
            question: 'What sustainable practices do you recommend for petroleum operations?',
            answer: "We recommend practices such as efficient resource utilization, waste reduction, and environmental impact mitigation to ensure sustainable petroleum operations.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${PetroleumCons})`,
                backgroundPosition: 'bottom'
            }}>
                 <MetaTags
        title="Petroleum Consulting Service in Townsville | Smart Resources QLD"
        description="Townsville petroleum expertise at your fingertips. Smart Resources QLD offers strategic consulting for exploration, production & regulatory compliance."
        keywords="Petroleum Consulting Service in Townsville"
        identifyUrl="https://smartresourcesqld.com.au/petroleum-consulting/townsville"
        DCTitle="Petroleum Consulting Service in Townsville | Smart Resources QLD"
        DCDescription="Townsville petroleum expertise at your fingertips. Smart Resources QLD offers strategic consulting for exploration, production & regulatory compliance."
        DCSubject="Petroleum Consulting Service in Townsville"
        Canonical="https://smartresourcesqld.com.au/petroleum-consulting/townsville"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Petroleum Consulting Service in Townsville | Smart Resources QLD"
        ogDescription="Townsville petroleum expertise at your fingertips. Smart Resources QLD offers strategic consulting for exploration, production & regulatory compliance."
        ogUrl="https://smartresourcesqld.com.au/petroleum-consulting/townsville"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Petroleum Consulting in Townsville</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/petroleum-consulting">Petroleum Consulting</NavLink></li>
                        <li>Townsville</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Expert Petroleum Consulting Services in Townsville</h1>
                                        <p>Welcome to Smart Resources QLD's Petroleum Consulting services page for Townsville. Our expert team is dedicated to providing comprehensive consulting services tailored to the unique needs of the petroleum industry in this strategic North Queensland city. Whether you're involved in exploration, production, or refining, we offer the insights and strategies you need to succeed.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg pet_banner_small">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Contact us today to discover how we can enhance your petroleum operations in Townsville!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={PetLeft} alt="" className="img-fluid" style={{objectFit: 'center top'}} /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Petroleum Consulting in Townsville?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Strategic Location:    </strong>Townsville's proximity to key petroleum exploration and production sites makes it an ideal hub for petroleum operations. Our consultants help you leverage this strategic advantage.
                                                    </li>
                                                    <li>
                                                        <strong>Local Market Expertise:    </strong> With deep knowledge of the local petroleum sector, we provide tailored insights and strategies that address the specific challenges and opportunities in the region.
                                                    </li>
                                                    <li>
                                                        <strong>Sustainable Practices:  </strong> We emphasize sustainable and environmentally responsible practices, helping you minimize impact while maximizing efficiency and profitability.
                                                    </li>
                                                    <li>
                                                        <strong>Regulatory Compliance:  </strong> Navigating the complex regulatory landscape is crucial in the petroleum industry. Our team ensures your operations comply with all relevant local, state, and federal regulations.
                                                    </li>
                                                    <li>
                                                        <strong>Advanced Technology: </strong> We utilize the latest technologies and methodologies in petroleum engineering and project management to deliver innovative solutions.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Experienced Professionals:   </strong>Our team of experienced petroleum consultants brings extensive industry knowledge and local expertise to your projects.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions:  </strong> We provide customized consulting services to meet your unique needs, whether you're starting a new project or optimizing an existing operation.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Track Record:  </strong> Our successful track record in the Townsville petroleum sector demonstrates our ability to deliver results and add value to your operations.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Focused Approach: </strong> We prioritize your goals and work closely with you to develop strategies that align with your business objectives.
                                                    </li>
                                                    {/* <li>
                                                        <strong>Local Expertise: </strong> Our team is well-versed in the specific economic landscape and industry trends of Townsville, ensuring relevant and impactful data solutions.
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={PetRight} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageTownsVille;