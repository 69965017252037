import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner1 from '../src/images/data--banner--02.webp';
import Petroleumbanner from '../src/images/data-banner.webp';
import DataCons1213 from "../src/images/data_cons_1213.webp";
import Data222 from "../src/images/data_222.webp";
import Data223 from "../src/images/data_223.webp";
import RealWhyServices from "../src/images/real_estate_why_choose_service.webp";
import faq1 from '../src/images/faq_banner.webp';
import RealEstateBanner111 from "../src/images/realestatebanner_111.webp";
import MiddleRealEstateBanner from "../src/images/middle_real_estate_banner.webp";
import RealEstateChooseBanner from "../src/images/real_estate_why_choose_us.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageTownsVille() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'What makes Townsville a good location for real estate investment?',
            answer: 'Townsville`s strategic location, growing infrastructure, and vibrant economy make it a prime spot for real estate investment. The city offers a mix of residential, commercial, and industrial opportunities.',
        },
        {
            question: 'How do you determine the value of a property in Townsville?',
            answer: "We use a combination of market analysis, recent sales data, and property condition assessments to determine accurate property valuations.",
        },
        {
            question: 'What types of properties are in high demand in Townsville?',
            answer: "High-demand properties in Townsville include family homes in good school districts, modern apartments near the city center, and commercial spaces in developing areas.",
        },
        {
            question: 'Can you help with both buying and selling properties?',
            answer: 'Yes, our services cover both buying and selling properties. We provide comprehensive support to ensure smooth and successful transactions.',
        },
        {
            question: 'What are the benefits of using a real estate consultant in Townsville?',
            answer: "Using a real estate consultant provides you with expert market insights, negotiation skills, and personalized service, helping you make informed decisions and achieve better outcomes.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${RealEstateBanner111})`, backgroundPosition: 'left center',
            }}>
                 <MetaTags
        title="Real Estate Consulting Service in Townsville | Smart Resources QLD"
        description="Need expert real estate advice in Townsville? Smart Resources QLD offers comprehensive consulting services to help you navigate the Townsville property market."
        keywords="Real Estate Consulting Service in Townsville"
        identifyUrl="https://smartresourcesqld.com.au/real-estate-consulting/townsville"
        DCTitle="Real Estate Consulting Service in Townsville | Smart Resources QLD"
        DCDescription="Need expert real estate advice in Townsville? Smart Resources QLD offers comprehensive consulting services to help you navigate the Townsville property market."
        DCSubject="Real Estate Consulting Service in Townsville"
        Canonical="https://smartresourcesqld.com.au/real-estate-consulting/townsville"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate Consulting Service in Townsville | Smart Resources QLD"
        ogDescription="Need expert real estate advice in Townsville? Smart Resources QLD offers comprehensive consulting services to help you navigate the Townsville property market."
        ogUrl="https://smartresourcesqld.com.au/real-estate-consulting/townsville"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Real Estate Consulting in Townsville</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/real-estate-consulting">Real Estate Consulting</NavLink></li>
                        <li>Townsville</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Expert Real Estate Consulting Services in Townsville</h1>
                                        <p>Welcome to Smart Resources QLD's Real Estate Consulting services page for Townsville. Our expert team is dedicated to helping you navigate the dynamic real estate market in this vibrant North Queensland city. Whether you're buying, selling, or investing, our tailored solutions and local expertise will guide you every step of the way.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg real_estate_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Contact us today to discover how we can help you achieve your real estate goals in <strong>Townsville!</strong>
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={RealEstateChooseBanner} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Real Estate Consulting in Townsville?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Strategic Growth: </strong>Townsville is experiencing significant growth, with numerous infrastructure projects and development opportunities. Our consultants can help you capitalize on these trends.
                                                    </li>
                                                    <li>
                                                        <strong>Local Market Knowledge: </strong> Transform complex data sets into intuitive visual representations for easy interpretation.
                                                    </li>
                                                    <li>
                                                        <strong>Machine Learning: </strong> With our deep understanding of Townsville's real estate market, we provide insights into the best neighborhoods, emerging trends, and investment hotspots.
                                                    </li>
                                                    <li>
                                                        <strong>Personalized Services: </strong> We offer customized solutions that cater to your unique needs, whether you're looking for residential properties, commercial spaces, or investment opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Comprehensive Support: </strong> From market analysis and property valuations to negotiations and closing deals, we support you through every stage of the real estate process.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Experienced Team: </strong>Our team of experienced real estate professionals brings extensive knowledge and expertise to every project, ensuring you receive the best advice and service.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Centered Approach:  </strong> We prioritize your needs and goals, working closely with you to develop strategies that align with your objectives.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Track Record: </strong> Our successful track record in Townsville's real estate market speaks for itself. We have helped numerous clients achieve their real estate goals.
                                                    </li>
                                                    <li>
                                                        <strong>Innovative Solutions:  </strong> We utilize the latest technology and innovative strategies to provide you with the most accurate market insights and effective solutions.
                                                    </li>
                                                    {/* <li>
                                                        <strong>Local Expertise: </strong> Our team is well-versed in the specific economic landscape and industry trends of Townsville, ensuring relevant and impactful data solutions.
                                                    </li> */}
                                                </ul>
                                                {/* <p></p> */}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={RealWhyServices} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageTownsVille;