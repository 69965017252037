import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner3 from '../src/images/rockamphton_bg.webp';
import RockampthonAngel from "../src/images/angel_rockampthon.webp";
import InvestmentMoney from "../src/images/money_investment.webp";
import InvestMentRight from "../src/images/investment_right.webp";
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
import Data226 from "../src/images/middle_img.webp";
// import Data224 from "../src/images/data_223.jpg";
// import Data225 from "../src/images/data_225.jpg";
import Data121348 from "../src/images/last_banner.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageRockhampton() {


    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'What advantages do angel investors offer to startups?',
            answer: 'Angel investors provide early-stage funding, mentorship, strategic advice, and valuable industry connections, all of which are crucial for the growth of your startup.',
        },
        {
            question: 'How do your consulting services improve my chances of attracting angel investment?',
            answer: "We assist in creating a strong business plan and pitch deck, and leverage our network to connect you with potential investors, increasing your chances of securing investment.",
        },
        {
            question: 'What types of startups are angel investors in Rockhampton interested in?',
            answer: "Angel investors in Rockhampton are attracted to startups with innovative ideas, scalable business models, and significant market potential.",
        },
        {
            question: 'What should be included in a pitch to angel investors?',
            answer: 'Your pitch should include a detailed business plan, market analysis, financial projections, and a compelling value proposition that showcases your startup`s potential.',
        },
        {
            question: 'How long does it typically take to secure angel investment?',
            answer: "Securing angel investment typically involves several months of preparation, pitching, due diligence, and negotiation before finalizing the investment agreement.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${RockampthonAngel})`,
                backgroundPosition:"bottom"
            }}>
                 <MetaTags
        title="Angel Investor Service Rockhampton | Smart Resources QLD"
        description="Connect with Rockhampton, Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        keywords="Angel Investor Service Rockhampton"
        identifyUrl="https://smartresourcesqld.com.au/angel-investor/rockhampton"
        DCTitle="Angel Investor Service Rockhampton | Smart Resources QLD"
        DCDescription="Connect with Rockhampton, Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        DCSubject="Angel Investor Service Rockhampton"
        Canonical="https://smartresourcesqld.com.au/angel-investor/rockhampton"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Angel Investor Service Rockhampton | Smart Resources QLD"
        ogDescription="Connect with Rockhampton, Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        ogUrl="https://smartresourcesqld.com.au/angel-investor/rockhampton"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Angel Investor Consulting in Rockhampton</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/angel-investor">Angel Investor</NavLink></li>
                        <li>Rockhampton</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Connect with Angel Investors in Rockhampton: Comprehensive Consulting Services </h1>
                                        <p>Welcome to Smart Resources QLD's Angel Investor Consulting services page for Rockhampton. As a key economic center in Central Queensland, Rockhampton offers numerous opportunities for startups seeking angel investment. Our expert team provides comprehensive consulting services to help you secure the funding needed to grow your business.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg good_investor_2">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Contact us today to find out how we can help you attract angel investors in Rockhampton!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={InvestmentMoney} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Angel Investor Consulting in Rockhampton? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Economic Hub:   </strong>Rockhampton's strategic location and economic activity make it an ideal place for startups looking to attract angel investors. Our consultants help you tap into this vibrant market.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:   </strong> With in-depth knowledge of Rockhampton's business environment, we provide tailored strategies to connect you with the right investors.
                                                    </li>
                                                    <li>
                                                        <strong>Wide Network: </strong> We maintain a broad network of angel investors who are interested in funding high-potential startups in Rockhampton. Our connections can facilitate access to essential investment opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Strategic Planning: </strong> We help you develop compelling business plans and pitch presentations that effectively communicate your business's potential to investors.
                                                    </li>
                                                    <li>
                                                        <strong>Comprehensive Support: </strong> Our consulting services cover all stages of the investment process, from initial funding rounds to growth phases, ensuring continuous support for your business.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Experienced Professionals:   </strong>Our team of seasoned consultants brings extensive knowledge in angel investment and startup development to your business.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions: </strong> We offer customized consulting services designed to meet your specific needs, whether you're a new startup or an established business seeking additional funding.
                                                    </li>

                                                    <li>
                                                        <strong>Successful Track Record: </strong> Our history of successfully connecting startups with angel investors in Rockhampton demonstrates our capability to deliver results.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Focused Approach: </strong> We prioritize your business goals and work collaboratively to develop strategies that align with your objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={InvestMentRight} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>




                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageRockhampton;