import React from 'react';
import './Stlye.css';
import  banner01 from "./images/banner-01.webp"
import  aboutsection from "./images/about-section-01.webp"
import  theme23 from "./images/theme23.webp"
import  mining1 from "./images/mining1.webp"
import  fuelprice from "./images/fuel-price.webp"
import  realestate1 from "./images/real-estate1.webp"
import  datacons from "./images/data_cons.webp"
import  angelinvestor from "./images/angel-investor.webp";
import {Tab, Nav} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import MetaTags from './MetaTags';
const paragraphStyle = {
    color: 'white', // Set text color to white
  };
//   const myStyle = {
//     backgroundImage: "url(./src/images/about-banner.jpg)",
//     // height: "100vh",
//     // marginTop: "-70px",
//     // fontSize: "50px",
//     // backgroundSize: "cover",
//     // backgroundRepeat: "no-repeat",
// };
function Aboutus() {
  // const aboutsection = './images/about-section-01.webp';
  return (
    <>
      

      <section className="innr_banner about_innr_banner">

      <MetaTags
        title="Australian Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service Company"
        description="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        keywords="Australian Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service Company"
        identifyUrl="https://smartresourcesqld.com.au/about-us"
        DCTitle="Australian Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service Company"
        DCDescription="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        DCSubject="Australian Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service Company"
        Canonical="https://smartresourcesqld.com.au/about-us"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Australian Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service Company"
        ogDescription="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        ogUrl="https://smartresourcesqld.com.au/about-us"
      />
        <div className="page_title_innr">
          <div className="page_title">About Us</div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li>About Us</li>
          </ul>
        </div>
      </section>

      <section className="global_stack_holder">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="global_left">
                <div className="global_label_top">
                  About Us
                </div>
                <h1>Empowering Growth Through Innovation</h1>
                <p>At Smart Resources QLD, we are committed to empowering Queensland businesses through innovation, expertise, and tailored solutions. Our team is dedicated to driving success for our clients, whether in real estate, mining, petroleum, data consulting, or angel investment. 

</p>
                <p>With a focus on collaboration and sustainability, we strive to exceed expectations and deliver tangible results that propel businesses forward. Guided by our core values of integrity, excellence, and client satisfaction, we approach each project with enthusiasm and a commitment to excellence. Partner with us to unlock new opportunities and achieve your business objectives with confidence.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="ct_banner_1">
                <div className="ct_banner_img">
                  <img src={banner01} className="img-fluid" alt="Banner" />
                </div>
                <h3 className="ct-banner-title" data-aos="fade-left">5 Years of Experience</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="global_left">
                        {/* <div className="global_label_top">
                            Pricing 
                        </div> */}
                        <h3>We're ready to share our advice and experience</h3>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="global_right">
                            <p>  At Smart Resources QLD, our journey is defined by a commitment to excellence and a passion for driving business success. With a focus on innovation and collaboration, we empower businesses to thrive in a dynamic marketplace. Explore our comprehensive range of services, tailored to meet the diverse needs of Queensland's business community.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-0">
                        <NavLink to="/real-estate-consulting" className="realestate" onClick={() => window.scrollTo(0, 0)}>
                            <div className="real_estate_consulting">
                                <div className="consulting_icon">
                                    <img src={realestate1} alt=''/>
                                </div>
                                <h4>Real Estate Consulting</h4>
                                <p>Unlock the full potential of your real estate investments with our comprehensive consulting services. From property valuation to market analysis, we provide expert guidance tailored to your unique goals, ensuring maximum returns and sustainable growth in the dynamic real estate market.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  px-0">
                        <NavLink to="/mining-consulting" className="realestate" onClick={() => window.scrollTo(0, 0)}>
                            <div className="real_estate_consulting">
                                <div className="consulting_icon">
                                    <img src={mining1} alt=''/>
                                </div>
                                <h4>Mining Consulting</h4>
                                <p>Navigate the complexities of the mining industry with confidence with our specialized consulting services. From feasibility studies to operational optimization, our experienced team offers strategic insights and innovative solutions to help you achieve operational excellence and maximize the value of your mining projects.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  px-0">
                        <NavLink to="/petroleum-consulting" className="realestate" onClick={() => window.scrollTo(0, 0)}>
                            <div className="real_estate_consulting">
                                <div className="consulting_icon">
                                    <img src={fuelprice} alt=''/>
                                </div>
                                <h4>Petroleum Consulting</h4>
                                <p>Stay ahead in the fast-paced petroleum sector with our strategic consulting services. Whether you're exploring new reserves or optimizing existing operations, our team of experts provides tailored solutions to address your specific challenges and capitalize on emerging opportunities, ensuring long-term success in the ever-evolving energy landscape.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-0">
                        <NavLink to="/data-science" className="realestate" onClick={() => window.scrollTo(0, 0)}>
                            <div className="real_estate_consulting">
                                <div className="consulting_icon">
                                    <img src={datacons} alt=''/>
                                </div>
                                <h4>Data Science</h4>
                                <p>Harness the power of data to drive informed decision-making and gain a competitive edge in your industry. Our data scientist are designed to help you unlock valuable insights from your data, optimize processes, and enhance performance, empowering you to make smarter, data-driven decisions that drive business growth and innovation.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 px-0">
                        <NavLink to="/angel-investor" className="realestate" onClick={() => window.scrollTo(0, 0)}>
                            <div className="real_estate_consulting">
                                <div className="consulting_icon">
                                    <img src={angelinvestor} alt=''/>
                                </div>
                                <h4>Angel Investor</h4>
                                <p>Empower promising ventures and fuel innovation as an angel investor with Smart Resources QLD. With our extensive network, industry expertise, and strategic guidance, we help you identify high-potential investment opportunities and maximize returns, supporting the growth and success of innovative startups and entrepreneurs in Queensland and beyond.</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>

      <section className="what_we_offer"  style={{ 
  backgroundImage: `url(${theme23})` }}>
        <div className="offer_innr">
          <div className="offer_innr_left"></div>
          <div className="offer_innr_right">
            <div className="offr_innr_innr_right">
              <h4>Like What We Offer</h4>
              <h3>Ready to Transform Your Business?</h3>
              <p>Take the first step towards unlocking your business's full potential with Smart Resources QLD. Whether you're seeking strategic guidance, innovative solutions, or investment opportunities, our dedicated team is here to help you succeed. Contact us today to schedule a consultation and discover how we can drive growth, innovation, and success for your business.</p>
              <div className="cont_us">
                <NavLink to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us <i className="fas fa-angle-right"></i></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_mission" style={{ 
  backgroundImage: `url(${aboutsection})` }}>
            <div className="container">
          <div className="our_mission_tabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="flex-column mission_tabs_left">
            <Nav.Item>
              <Nav.Link eventKey="first">Our Vision</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Our Mission</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className='mission_tabs_right'>
            <Tab.Pane eventKey="first">
                  <h4>Our Vision</h4>
                  <p>At Smart Resources QLD, our vision is to be the premier consulting firm, renowned for our expertise, integrity, and commitment to client success. We aim to be the go-to partner for businesses and individuals seeking transformative solutions and strategic insights across diverse industries. By driving innovation, fostering collaboration, and delivering measurable results, we aspire to shape a future where organizations thrive and communities flourish.</p>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
                <h4>Our Mision</h4>
                <p style={paragraphStyle}>
                  Our mission at Smart Resources QLD is to provide unparalleled consulting services across real estate, mining,
                  petroleum, data analytics, and angel investment sectors. We strive to empower our clients with innovative
                  solutions, strategic guidance, and exceptional support, fostering growth, sustainability, and prosperity in the
                  communities we serve.
                </p>
            </Tab.Pane>
          </Tab.Content>
    </Tab.Container>
    </div>
            </div>
        </section>
    </>
  );
}

export default Aboutus;
