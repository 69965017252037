import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner3 from '../src/images/rockamphton_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
import Data226 from "../src/images/middle_img.webp";
// import Data224 from "../src/images/data_223.jpg";
// import Data225 from "../src/images/data_225.jpg";
import Data121348 from "../src/images/last_banner.webp";
import MiningConsRockampthon from "../src/images/mining_cons_rockampthon.webp";
import MiningOpenPit from "../src/images/mining_open_pit.webp";
import MiningOpen222 from "../src/images/mining_open_222.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageRockhampton() {


    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why is Rockhampton an ideal location for mining operations?',
            answer: 'Rockhampton`s central location and robust infrastructure make it a strategic base for mining activities throughout Central Queensland.',
        },
        {
            question: 'How can your consulting services benefit my mining operations?',
            answer: "Our consulting services provide expert guidance, strategic planning, and operational support to optimize efficiency, sustainability, and profitability in your mining operations.",
        },
        {
            question: 'What types of mining projects do you support in Rockhampton?',
            answer: "We support a variety of mining projects, including mineral exploration, extraction, and processing across various commodities.",
        },
        {
            question: 'How do you ensure regulatory compliance for my mining operations?',
            answer: 'We stay up-to-date with all relevant regulations and work closely with you to ensure your operations meet all local, state, and federal compliance requirements.',
        },
        {
            question: 'What sustainable practices do you recommend for mining operations?',
            answer: "We recommend practices such as efficient resource utilization, waste reduction, and environmental impact mitigation to ensure responsible and sustainable mining operations.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${MiningConsRockampthon})`,
                backgroundPosition: 'center bottom'
            }}>
                 <MetaTags
        title="Mining Consulting Service in Rockhampton | Smart Resources QLD"
        description="Make informed Cairns real estate decisions with Smart Resources QLD's expert consulting. Get strategic advice & navigate the Rockhampton market with confidence."
        keywords="Mining Consulting Service in Rockhampton"
        identifyUrl="https://smartresourcesqld.com.au/mining-consulting/rockhampton"
        DCTitle="Mining Consulting Service in Rockhampton | Smart Resources QLD"
        DCDescription="Make informed Cairns real estate decisions with Smart Resources QLD's expert consulting. Get strategic advice & navigate the Rockhampton market with confidence."
        DCSubject="Mining Consulting Service in Rockhampton"
        Canonical="https://smartresourcesqld.com.au/mining-consulting/rockhampton"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Mining Consulting Service in Rockhampton | Smart Resources QLD"
        ogDescription="Make informed Cairns real estate decisions with Smart Resources QLD's expert consulting. Get strategic advice & navigate the Rockhampton market with confidence."
        ogUrl="https://smartresourcesqld.com.au/mining-consulting/rockhampton"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Mining Consulting in Rockhampton</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/mining-consulting">Mining Consulting</NavLink></li>
                        <li>Rockhampton</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Comprehensive Mining Consulting Solutions in Rockhampton </h1>
                                        <p>Welcome to Smart Resources QLD's Mining Consulting services page for Rockhampton. As a central hub for mining activities in Central Queensland, Rockhampton offers extensive opportunities for mining operations. Our expert team provides comprehensive consulting services to help you optimize your mining projects, ensuring efficiency, sustainability, and regulatory compliance.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg mining_short_image">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                Contact us today to discover how we can enhance your mining operations in Rockhampton!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={MiningOpenPit} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Mining Consulting in Rockhampton? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Central Location:    </strong>Rockhampton's central location makes it a strategic base for mining operations throughout Central Queensland. Our consultants can help you leverage this advantage.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:    </strong> With in-depth knowledge of Rockhampton's mining sector, we provide insights and strategies tailored to the region's specific needs and opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Sustainable Operations: </strong> We emphasize sustainable mining practices, helping you minimize environmental impact while maximizing resource extraction and operational efficiency.
                                                    </li>
                                                    <li>
                                                        <strong>Regulatory Compliance:  </strong> Our team ensures that your mining operations adhere to all necessary regulations, helping you avoid compliance issues and operational disruptions.
                                                    </li>
                                                    <li>
                                                        <strong>Advanced Techniques:  </strong> We utilize the latest technologies and best practices in mining engineering and project management to deliver innovative solutions.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Skilled Professionals:   </strong>Our team of experienced mining consultants brings a wealth of industry knowledge and local expertise to your projects.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions:  </strong> We provide customized consulting services to meet your unique needs, whether you're starting a new project or optimizing an existing one.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Success:  </strong> Our successful track record in the Rockhampton mining sector demonstrates our ability to deliver results and add value to your operations.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Focused Approach: </strong> We prioritize your goals and work collaboratively to develop strategies that align with your business objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={MiningOpen222} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>




                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageRockhampton;