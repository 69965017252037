import React from 'react';
import icon01 from './images/h4-icon-01.webp'
import icon02 from './images/h4-icon-02.webp'
import about02 from './images/s.webp'
const WhyChooseSection = () => {
  return (
    <section className="about_us_section why_choose">
      <div className="about_us_innr_sec">
        <div className="about_us_innr_left">
          <div className="about_left_heading">
            <div className="abt_sub_title choose_title">Why Choose Us</div>
            <h3 data-aos="flip-left" aos-anchor-placement="top-center">There are many consulting firms in this city but clients choose us all the time </h3>
            <p>Choose Smart Resources QLD for unparalleled expertise, personalized support, and a proven track record of driving business success. With a dedicated focus on innovation, sustainability, and growth, we are your trusted partner in achieving your business objectives.</p>
          </div>
          
          <div className="secured_management">
            <div className="secured_innr_management">
              <div className="innr_management_top">
                <div className="item_icon">
                  
                    <img src={icon01} alt="Icon 1" />
                  
                </div>
                <div className="item_holder">
                  <h3 className="item--title">Expertise</h3>
                  <div className="item--description">Industry leaders with a depth of knowledge to guide your business<br/> towards excellence.</div>
                </div>
              </div>

              <div className="innr_management_top">
                <div className="item_icon">
                  
                    <img src={icon02} alt="Icon 2" />
                 
                </div>
                <div className="item_holder">
                  <h3 className="item--title">Support</h3>
                  <div className="item--description">Dedicated assistance tailored to your needs, ensuring your success<br></br> every step of the way.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about_us_innr_right" data-aos="fade-left" aos-anchor-placement="top-center">
          <figure className="about_img">
          <img src={about02} className="img-fluid" alt="About" style={{ borderRadius: '10%' }} />


          </figure>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSection;
