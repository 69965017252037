import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaPhone, FaFacebookF, FaTwitter, FaDribbble, FaBehance } from 'react-icons/fa';
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import smartresourceslogo1 from "./images/smartresourceslogo1.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom'; // Added NavLink

import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div>
    <footer className="footer">
      <div className="footer_upper">
        <div className="footer_left">
          <div className="footer_logo">
            <img src={smartresourceslogo1} className="img-fluid" alt="Smart Resources Logo" />
          </div>
          <p>Smart Resources Qld is a trusted advisor and partner to the petroleum industry, providing integrated solutions and advisory services to oil and gas companies in Pacific.</p>
        </div>
        <div className="footer_middle px-4">
          <div className="ct_title">
            <h3>
              <span>Links</span><i></i>
            </h3>
          </div>
          <div className="menu-menu-footer-links-container">
          <ul className="menu-menu-footer-links">
              <li><IoIosArrowForward /><NavLink to="/" className="active-link" onClick={() => window.scrollTo(0, 0)}>Home</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/about-us" className="active-link" onClick={() => window.scrollTo(0, 0)}>About Us</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/our-operations" className="active-link" onClick={() => window.scrollTo(0, 0)}>Our Operations</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/real-estate-consulting" className="active-link" onClick={() => window.scrollTo(0, 0)}>Real Estate Consulting</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/mining-consulting" className="active-link" onClick={() => window.scrollTo(0, 0)}>Mining Consulting</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/petroleum-consulting" className="active-link" onClick={() => window.scrollTo(0, 0)}>Petroleum Consulting</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/data-science" className="active-link" onClick={() => window.scrollTo(0, 0)}>Data Science</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/angel-investor" className="active-link" onClick={() => window.scrollTo(0, 0)}>Angel Investor</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/blog" className="active-link" onClick={() => window.scrollTo(0, 0)}>Blog</NavLink></li>
              <li><IoIosArrowForward /><NavLink to="/contact-us" className="active-link" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink></li>
            </ul>
          </div>
        </div>
        <div className="footer_right">
          <div className="ct_title">
            <h3>
              <span>Address</span><i></i>
            </h3>
          </div>
          <div className="footer_cont">
            <div className="footer_cont_upper">
              <FaMapMarkerAlt />
              <span className="ct-contact-content"> Suite 5 "Smithfield Business Centre" <br />1057 Captain Cook Highway,<br /> Smithfield QLD 4878</span>
            </div>
            <div className="footer_cont_upper">
            <FontAwesomeIcon icon={faPhone} />
              <span className="ct-contact-content">  1300 466 855  </span>
            </div>
            <div className="open_hours">
              <div className="hours"> Open Hours:</div>
              <div className="hours_bottom">
                Mon - Fri :  8 am - 5 pm,<br />
                Sat      :   9 am - 12 pm <br/>
                Sunday   :   CLOSED
              </div>
            </div>
            <div className="social_iconss mt-4">
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61557947535856"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a target="_blank" href="https://www.instagram.com/smartresourcesqld"><FontAwesomeIcon icon={faInstagram} /></a>
              <a  target="_blank" href="https://www.linkedin.com/company/smart-resources-qld/"><FontAwesomeIcon icon={faLinkedin} /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_lower">
        <p>2024 &copy; All rights reserved by <a target="_blank" href="https://www.adzguru.co">Adzguru</a></p>
      </div>
      
    </footer>
    {showScrollButton && (
        <div>
          <button className="to-top-button" onClick={scrollToTop}><IoIosArrowUp /></button>
        </div>
      )}
    </div>
  );
};

export default Footer;