// Header.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import bannerImage from './images/smart-banner-2.jpg';
import bannerImage1 from './images/smart-banner-3.jpg';
import bannerImage2 from './images/smart-banner-4.jpg';
import icon1 from './images/mortgage.webp';
import icon2 from './images/mining3.webp';
import icon3 from './images/petrol-pump.webp';
import icon4 from './images/data-science.webp';
import icon5 from './images/wings.webp';
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MetaTags from './MetaTags';
const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true,
    autoplaySpeed:6000,
    slidesToShow: 1,
    pauseOnFocus: false, 
  pauseOnHover: false,
    slidesToScroll: 1
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  
  return (
    <div>
    <header className="innr_header">
      
    </header>
    <div className="consultio_content">

    <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service"
        description="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service"
        identifyUrl="https://smartresourcesqld.com.au/"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service"
        DCDescription="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service"
        Canonical="https://smartresourcesqld.com.au/"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting Service"
        ogDescription="Smart Resources QLD is a consulting firm specializing in real estate, mining, petroleum, data science, and angel investor consulting. We focus on helping businesses achieve their innovation, sustainability, and growth goals."
        ogUrl="https://smartresourcesqld.com.au/"
      />
          <Slider {...settings} className="cont_partts">
            <div className="cont_carousel">
                <figure>
                  <img src={bannerImage}alt="banner" />
                </figure>
                <div className='banner_caption_holder'>
                    <div className="banner_caption">
                          <h2 className="wow fadeInUp"><span className="d-flex"><span className="discover">Discover  </span> <span className='smart'> Smart</span></span> <span className="resources">Resources</span></h2> 
                          <h3>Your Premier Consulting Partner in QLD</h3> 
                          {/* <div className="text-center banner_cap_button wow fadeInUp"><NavLink to="/our-operations" className="banner_cta_button" onClick={() => window.scrollTo(0, 0)}><FontAwesomeIcon icon={faGear} /><span>View services</span></NavLink></div> */}
                          
                    </div>
                    <p>Unlocking Potential Across Industries</p>
                </div>
            </div>

            <div className="cont_carousel cont_car_overlay">
                <figure>
                  <img src={bannerImage1}alt="banner" />
                </figure>
                <div className='banner_caption_holder cont_car_holder'>
                    <div className="banner_caption">
                          <h2 className="wow fadeInUp">Mining Consulting</h2> 
                          <h3>From exploration to extraction, optimize your mining operations with Smart Resources. Our expert guidance ensures efficient processes and sustainable practices.</h3> 
                          {/* <div className="text-center banner_cap_button wow fadeInUp"><NavLink to="/our-operations" className="banner_cta_button" onClick={() => window.scrollTo(0, 0)}><FontAwesomeIcon icon={faGear} /><span>View services</span></NavLink></div> */}
                          
                    </div>
                    {/* <p>Unlocking Potential Across Industries</p> */}
                </div>
            </div>

            <div className="cont_carousel cont_car_overlay overlay-0">
                <figure>
                  <img src={bannerImage2}alt="banner" />
                </figure>
                <div className='banner_caption_holder cont_car_holder petroleum_holder'>
                    <div className="banner_caption">
                          <h2 className="wow fadeInUp">Petroleum Consulting</h2>
                          <h3>Stay ahead in the petroleum sector<br/> with Smart Resources. </h3>
                          <h4>We provide strategic advice and innovative solutions for   </h4>

                          <ul>
                            <li>Exploration,</li>
                            <li>Production,</li>
                            <li>and Beyond.</li>
                          </ul>
                          {/* <h3>From exploration to extraction, optimize your mining operations with Smart Resources. Our expert guidance ensures efficient processes and sustainable practices.</h3>  */}
                          {/* <div className="text-center banner_cap_button wow fadeInUp"><NavLink to="/our-operations" className="banner_cta_button" onClick={() => window.scrollTo(0, 0)}><FontAwesomeIcon icon={faGear} /><span>View services</span></NavLink></div> */}
                          
                    </div>
                    {/* <p>Unlocking Potential Across Industries</p> */}
                </div>
            </div>  
        </Slider>
      <div className="banner_bottom_partts ">
        <NavLink to="/real-estate-consulting" className="banner_bottom_innr_partts" onClick={() => window.scrollTo(0, 0)}>
            <div className="item-icon">
                <img src={icon1}/>
            </div>
            <div className="item--holder">
                <h3 className="item--title">Real Estate Consulting</h3>
         
            </div>
        </NavLink>
        <NavLink to="/mining-consulting" className="banner_bottom_innr_partts" onClick={() => window.scrollTo(0, 0)}>
            <div className="item-icon">
                <img src={icon2}/>
            </div>
            <div className="item--holder">
                <h3 className="item--title">Mining Consulting</h3>
               
            </div>
        </NavLink>
        <NavLink to="/petroleum-consulting" className="banner_bottom_innr_partts" onClick={() => window.scrollTo(0, 0)}>
            <div className="item-icon">
                <img src={icon3}/>
            </div>
            <div className="item--holder">
                <h3 className="item--title">Petroleum Consulting</h3>
                
            </div>
        </NavLink>
        <NavLink to="/data-science" className="banner_bottom_innr_partts" onClick={() => window.scrollTo(0, 0)}>
            
                <div className="item-icon">
                    <img src={icon4}/>
                </div>
                <div className="item--holder">
                    <h3 className="item--title">Data Science</h3>
            
                </div>

        </NavLink>
        <NavLink to="/angel-investor" className="banner_bottom_innr_partts" onClick={() => window.scrollTo(0, 0)}>
            
                <div className="item-icon">
                    <img src={icon5}/>
                </div>
                <div className="item--holder">
                    <h3 className="item--title">Angel Investor</h3>
            
                </div>

        </NavLink>
      </div>
    </div>
    <section className="about_us_section">
      <div className="about_us_innr_sec">
   
      </div>
    </section>
   
  </div>
  );
};

export default Header;
