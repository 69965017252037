import React, { useState } from 'react';
import Petroleumbanner from '../images/prpost.webp'; 
import bannerImage from '../images/mingpost1.webp'; 
import bannerImage1 from '../images/realpost1.webp'; 
import bannerImage2 from '../images/datapost.webp' ; 
import bannerImage4 from '../images/prpost1.webp'; 
import bannerImage5 from '../images/fulepost1.webp';

// import emailw from '../images/email12.webp'; 
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom';
import './Blogstyle.css';
import {Container, Row, Col} from "react-bootstrap";
import MetaTags from '../MetaTags';
// import {}
const BlogPost = () => {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('service_ofw3ypm', 'template_ki9qfe5', e.target, 'II-fnB7G49fYlfyTr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // Reset form fields
        setComment('');
        setName('');
        setEmail('');
        setWebsite('');
    };
    const styles = {
        container: {
         
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Arial, sans-serif',
        },
        banner: {
          position: 'relative',
          backgroundColor: '#333',
          color: '#fff',
          // padding: '20px',
          width: '100%',
          zIndex:'1',
          textAlign: 'center',
          position: 'relative',
        },
        bannerOverlay: {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.52)',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        },
      
      
        bannerBlogHeading: {
          position: 'absolute',
          top:'50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color:'#fff',
          fontSize: '40px',
        },
        bannerImage: {
          // maxWidth: '100%',
          // maxHeight: '300px', // Adjust the height as needed
          width:'100%',
          height:'420px',
          objectFit:'cover',
          objectPosition:'center',
        },
        itemContainer: {
          marginTop:"35px",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          // maxWidth: '100%',
          margin: '0 auto',
        },
        // itemRow: {
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   width: '100%',
        // },
      
        breadCrumb: {
          position: 'absolute',
          top: '60%',
          left:'50%',
          transform: 'translateX(-50%)',
        },
        item: {
          position: 'relative',
          // width: '296px',
          // height: '180px',
          backgroundColor: '#eee',
          border: '1px solid #ccc',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          textDecoration: 'none',
          color: '#333',
          transition: 'transform 0.3s ease',
          overflow: 'hidden', // Hide overflowing content
        },
        itemTitle: {
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '5px',
          opacity: 0,
          transition: 'opacity 0.3s ease',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          textAlign: 'center',
          width: '100%',
          padding: '0 10px',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
        },
        itemDetails: {
          fontSize: '14px',
          opacity: 0,
          transition: 'opacity 0.3s ease',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(calc(-50% - 20px))', // Adjust position to accommodate title
          textAlign: 'center',
          width: '100%',
          padding: '0 10px',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
        },
        itemImage: {
          width: '100%',
          height: '300px',
          transition: 'transform 0.3s ease',
          objectFit: 'cover',
          objectPosition: 'center top',
        },
        // Different styles for item1, item2, item3
        item123: {
          backgroundColor: '#f0f0f0',
          color: '#666',
        },
        // Different styles for item4
        item4: {
          backgroundColor: '#ddd',
          color: '#444',
          marginLeft: "150px"
        },
        // Different styles for item5
        item5: {
          backgroundColor: '#ccc',
          color: '#333',
          marginRight: "150px"
        },
      
      
      
       
      };
    return (
        
    <>
    
    <div style={styles.banner} className='Banner_blog'>
    <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        description="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        identifyUrl="https://smartresourcesqld.com.au/petroleumpost"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        DCDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        Canonical="https://smartresourcesqld.com.au/petroleumpost"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        ogDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors"
        ogUrl="https://smartresourcesqld.com.au/petroleumpost"
      />
        {/* <h1>Website Banner</h1> */}
        <img src={bannerImage} alt="Banner" style={styles.bannerImage} />
        <div style={styles.bannerOverlay}></div>
        <div style={styles.bannerBlogHeading}>Blog</div>
        <ul className="bread_crumb" style={styles.breadCrumb}><li><a href="/">Home</a></li><li>Petroleum Post</li></ul>
      </div>
    
    <section className="work_safety abt_work_safety blog_partts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-8">
                        <div className="blog_left_inner">
                        <h1>Fueling Success: The Power of Petroleum Consulting</h1>
                            <div>
                                <figure>
                                    <img src= {Petroleumbanner} />
                                </figure>
                                <div className="commnt_sec">
                                   
                                </div>
                            </div>
                            <div className="blog_para">
                            
                                <div className="blog_innr_para">
                                    
                                    <p>In the dynamic realm of the petroleum industry, where challenges abound and opportunities are ever-present, petroleum consulting emerges as a beacon of expertise and strategic guidance. Let's delve into the world of petroleum consulting to understand its pivotal role in driving success and innovation in this vital sector.
</p>
                                    <label >Exploration and Production Optimization:</label>
                                    <ul>
                                        <li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>At the heart of the petroleum industry lies the quest to discover and extract hydrocarbon reserves efficiently and economically. Petroleum consultants bring specialized knowledge in geology, reservoir engineering, and drilling technology to bear, assisting companies in optimizing exploration and production activities. From reservoir characterization and well placement optimization to enhanced oil recovery techniques, they help maximize recovery rates and unlock the full potential of hydrocarbon assets.</li>
                                        <label>Strategic Planning and Asset Management:</label>
                                        <li><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>
                                        Navigating the complex landscape of petroleum markets requires foresight, agility, and strategic acumen. Petroleum consultants provide invaluable market intelligence and strategic guidance to help companies adapt to changing market dynamics and capitalize on emerging opportunities. Whether it's portfolio optimization, asset valuation, or investment analysis, their insights enable informed decision-making and drive long-term value creation.</li>
                                    
<label>Health, Safety, and Environmental Compliance:</label>
<li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>In an industry where operational risks are inherent, ensuring the health, safety, and environmental sustainability of petroleum operations is paramount. Consultants specialize in developing robust health and safety management systems, conducting risk assessments, and implementing environmental compliance frameworks to safeguard workers and the environment. By staying abreast of regulatory changes and industry best practices, they help companies mitigate risks, minimize liabilities, and foster a culture of safety and environmental stewardship.
</li>





<label>Technical Expertise and Innovation:</label>
<li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>The petroleum industry is characterized by rapid technological advancements and continuous innovation. Petroleum consultants stay at the forefront of technological trends, offering expertise in areas such as reservoir modeling, hydraulic fracturing, and digital oilfield solutions. By leveraging cutting-edge technologies and best practices, they help companies optimize operations, reduce costs, and enhance efficiency throughout the value chain.
</li>



<label>Market Intelligence and Supply Chain Optimization:</label>
<li  style={{textAlign: 'justify'}}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Optimizing the petroleum supply chain is critical for maintaining competitiveness and maximizing profitability. Consultants provide comprehensive market analysis, supply-demand forecasting, and logistics optimization services to help companies navigate complex market dynamics and ensure timely delivery of products to customers. Whether it's optimizing transportation routes, managing inventory levels, or negotiating supplier contracts, their insights enable companies to streamline operations and enhance supply chain resilience.<br/><br/>In conclusion, petroleum consulting serves as a catalyst for success and innovation in the petroleum industry, offering specialized expertise, strategic guidance, and invaluable support across every facet of the value chain. Whether it's optimizing production processes, navigating regulatory challenges, or capitalizing on market opportunities, consultants play a pivotal role in driving sustainable growth, profitability, and resilience in this vital sector.

In conclusion, real estate consulting plays a pivotal role in today's dynamic property market. By harnessing their expertise, insights, and strategic guidance, individuals and businesses can navigate the complexities of real estate transactions with confidence. Whether you're a buyer, seller, investor, or developer, partnering with a reputable consultant can make all the difference in achieving your objectives and maximizing returns on your investment.
</li>
         
                                    </ul>
                                   
                                </div>
                                {/* Other content omitted for brevity */}
                            </div>
                            {/* Other content omitted for brevity */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="blog_right_innr">
                        <div className="blog_cat post_cat">
                        <h5>Recent Posts</h5>
                        <ul className="post_img">
                            <li>
                                <figure>
                                    <img src= {bannerImage1} />
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/post" onClick={() => window.scrollTo(0, 0)}>Real Estate Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                               
                               
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src={bannerImage}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/miningpost" onClick={() => window.scrollTo(0, 0)}>Mining Post</NavLink></h4>

                                    <div className="posted_on">
                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src={bannerImage4}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>Petroleum Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>

                            <li>
                                <figure>
                                    <img src={bannerImage2}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/datapost" onClick={() => window.scrollTo(0, 0)}>Data Post</NavLink></h4>

                                    <div className="posted_on">
                                        <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                    </div>
                                </div>
                            </li>

                            <li>
                                <figure>
                                    <img src={bannerImage5}/>
                                </figure>
                                <div className="post_text">
                                <h4><NavLink to="/fulepost" onClick={() => window.scrollTo(0, 0)}>Fuel Post</NavLink></h4>

                                    <div className="posted_on">
                                        
                            <i className="fa-regular fa-calendar-days"></i> May 13, 2024 
                                    </div>
                                </div>
                            </li>
                        </ul>
        </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12" md="12">
                    <div className="leave_a_reply">
            <h4>Leave A Reply</h4>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <textarea
                        className="form-control text_partts"
                        placeholder="Comments"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control reply_text text_partts1213"
                        placeholder="Name (required)"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        className="form-control reply_text text_partts1213 "
                        placeholder="Email (required)"
                        name="email"
                        style={{float: 'right'}}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                {/* <div className="form-group">
                    <input
                        type="url"
                        className="form-control reply_text"
                        placeholder="Website"
                        name="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div> */}

                <input
                    name="submit"
                    type="submit"
                    id="submit"
                    className="submit"
                    value="Post Comment"
                />
            </form>
                </div> 
                    </Col>    
                </Row>

            </div>
            
        </section>
    
        
    </>
    );
}

export default BlogPost;
