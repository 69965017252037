import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner1 from '../src/images/data--banner--02.webp';
import Petroleumbanner from '../src/images/data-banner.webp';
import DataCons1213 from "../src/images/data_cons_1213.webp";
import Data222 from "../src/images/data_222.webp";
import Data223 from "../src/images/data_223.webp";
import Data121345 from "../src/images/data__121345.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageTownsVille() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'How can data science benefit my business in Townsville?',
            answer: 'Data science can help your business by providing actionable insights, improving decision-making, optimizing operations, and driving growth through data-driven strategies.',
        },
        {
            question: 'What types of data can be analyzed?',
            answer: "We analyze various types of data, including customer data, operational data, financial data, and external data such as market trends and social media insights.",
        },
        {
            question: 'How do you ensure data privacy and security?',
            answer: "We adhere to strict data privacy and security protocols, including data encryption, secure data storage, and compliance with relevant regulations to protect your sensitive information.",
        },
        {
            question: 'Can small businesses in Townsville benefit from data science?',
            answer: 'Absolutely! Data science offers valuable insights for businesses of all sizes, helping small businesses optimize their operations, understand their customers better, and compete more effectively.',
        },
        {
            question: 'How long does it take to see results from data science projects?',
            answer: "The timeline for results varies depending on the complexity of the project and the specific goals. However, many businesses start seeing significant improvements within a few months of implementation.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${Petroleumbanner1})`
            }}>
                 <MetaTags
        title="Data Science Service Townsville | Smart Resources QLD"
        description="Transform your data into actionable insights with Smart Resources QLD's data science services. Townsville, Queensland-wide expertise for businesses of all sizes."
        keywords="Data Science Service Townsville"
        identifyUrl="https://smartresourcesqld.com.au/data-science/townsville"
        DCTitle="Data Science Service Townsville | Smart Resources QLD"
        DCDescription="Transform your data into actionable insights with Smart Resources QLD's data science services. Townsville, Queensland-wide expertise for businesses of all sizes."
        DCSubject="Data Science Service Townsville"
        Canonical="https://smartresourcesqld.com.au/data-science/townsville"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Data Science Service Townsville | Smart Resources QLD"
        ogDescription="Transform your data into actionable insights with Smart Resources QLD's data science services. Townsville, Queensland-wide expertise for businesses of all sizes."
        ogUrl="https://smartresourcesqld.com.au/data-science/townsville"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Data Science Services in Townsville</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/data-science">Data Science</NavLink></li>
                        <li>Townsville</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Unlock the Power of Data Science in Townsville</h1>
                                        <p>Welcome to our Data Science services page for Townsville, where we transform raw data into actionable insights to help your business thrive. Townsville, known for its vibrant community and robust economy, is the perfect place to harness the power of data science. Our expert team offers comprehensive data solutions tailored to meet the unique needs of businesses in Townsville.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Take advantage of our expertise and transform your business today
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={Data223} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Our Data Science Solutions in Townsville</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Predictive Analytics:   </strong>Forecast future trends and behaviors to stay ahead of the competition.
                                                    </li>
                                                    <li>
                                                        <strong>Data Visualization:   </strong> Transform complex data sets into intuitive visual representations for easy interpretation.
                                                    </li>
                                                    <li>
                                                        <strong>Machine Learning: </strong> Implement machine learning algorithms to automate processes and enhance decision-making.
                                                    </li>
                                                    <li>
                                                        <strong>Big Data Analytics: </strong> Analyze large volumes of data to uncover hidden patterns and insights.
                                                    </li>
                                                    <li>
                                                        <strong>Custom Data Solutions:</strong> Tailored data science services to meet the specific needs of your business in Townsville.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Data Science in Townsville? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Strategic Decision-Making:  </strong>Our data science services empower businesses in Townsville to make informed decisions by analyzing historical data, identifying trends, and predicting future outcomes.
                                                    </li>
                                                    <li>
                                                        <strong>Enhanced Efficiency: </strong> Utilize advanced analytics to streamline operations, reduce costs, and optimize resource allocation.
                                                    </li>

                                                    <li>
                                                        <strong>Customer Insights: </strong> Gain a deeper understanding of your customers' behavior and preferences, allowing for more targeted marketing and improved customer satisfaction.
                                                    </li>
                                                    <li>
                                                        <strong>Innovation and Growth: </strong> Leverage data-driven strategies to innovate your business model and drive sustainable growth in Townsville's dynamic market.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise: </strong> Our team is well-versed in the specific economic landscape and industry trends of Townsville, ensuring relevant and impactful data solutions.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={Data121345} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageTownsVille;