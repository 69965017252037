import React from 'react';
import funFactImage from './images/fun_fact_img.webp';

import funFactImage1 from './images/gallery-06.webp';
import funFactImage2 from './images/gallery-02.webp';
import funFactImage3 from './images/gallery-03.webp';
import './Stlye.css';
const FunFactSection = () => {
  return (
    <section className="funfact_partts" style={{ 
        backgroundImage: `url(${funFactImage})` 
      }}>
      <div className="funfact_innr">
        <div className="funfact_innr_left">
          <div className="about_left_heading">
            <div className="abt_sub_title">Our Works</div>
            <h3  data-aos="flip-left" aos-anchor-placement="top-center">Showcasing Our Impactful Collaborations</h3>
            <p data-aos="fade-right">
            Explore our portfolio of successful collaborations, showcasing our commitment to driving innovation and growth in Queensland's business landscape. From transformative projects to strategic partnerships, witness the impact of our work firsthand.
            </p>
          </div>
        </div>
        <div className="funfact_innr_right">
          <div className="fun_fact_innr_innr_right">
            <div className="fun_fct_innr_innr">
              <div className="funn_left">
                <div className="fun_innr_left">
                  {/* Team Members Counter */}
                  <div className="counter_black">
                    <div className="counter_image bg_image"  style={{ 
        backgroundImage: `url(${funFactImage})` 
      }} />
                    <div className="counter_holder">
                      {/* <div>
                        <CountUp end={105} duration={3} />
                        <span>+</span>
                      </div> */}
                      <div className="ct-counter-title">Tech Integration Excellence</div>
                    </div>
                  </div>

                  {/* Winning Awards Counter */}
                  <div className="counter_black">
                    <div className="counter_image bg_image" style={{ 
        backgroundImage: `url(${funFactImage1})` 
      }} />
                    <div className="counter_holder">
                      {/* <div>
                        <CountUp end={8} duration={3} /> Change '8' with your actual number
                        <span>+</span>
                      </div> */}
                      <div className="ct-counter-title">Market Expansion Mastery</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="funn_right">
                {/* Clients Feedback Counter */}
                <div className="counter_black">
                  <div className="counter_image bg_image" style={{ 
        backgroundImage: `url(${funFactImage2})` 
      }} />
                  <div className="counter_holder">
                    {/* <div>
                      <CountUp end={215} duration={3} /> 
                      <span>+</span>
                    </div> */}
                    <div className="ct-counter-title">Entrepreneurial Empowerment</div>
                  </div>
                </div>

                {/* Completed Works Counter */}
                <div className="counter_black">
                  <div className="counter_image bg_image" style={{ 
        backgroundImage: `url(${funFactImage3})` 
      }} />
                  <div className="counter_holder">
                    {/* <div>
                      <CountUp end={310} duration={3} /> 
                      <span>+</span>
                    </div> */}
                    <div className="ct-counter-title">Sustainability Solutions Brilliance</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFactSection;
