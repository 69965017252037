import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner3 from '../src/images/rockamphton_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
import Data226 from "../src/images/middle_img.webp";
// import Data224 from "../src/images/data_223.jpg";
// import Data225 from "../src/images/data_225.jpg";
import Data121348 from "../src/images/last_banner.webp";
import RealEstateRockampthon from "../src/images/realestate_rockamphton.webp";
import RockampthonRealEstate from "../src/images/rockampthon_real_estate111.webp";
import RealEstateWhyChooseUs1213 from "../src/images/real_estate_121312.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageRockhampton() {


    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why is Rockhampton a good place to invest in real estate?',
            answer: 'Rockhampton`s growing economy, infrastructure projects, and strategic location make it a great place for real estate investments with potential for strong returns.',
        },
        {
            question: 'How do you determine property values in Rockhampton?',
            answer: "We use market analysis, recent sales data, and property condition assessments to provide accurate property valuations.",
        },
        {
            question: 'What types of properties are popular in Rockhampton?',
            answer: "Popular properties include family homes in good school districts, commercial properties in developing areas, and investment properties near key infrastructure projects.",
        },
        {
            question: 'Can you assist with both residential and commercial real estate?',
            answer: 'Yes, we provide consulting services for both residential and commercial real estate, offering comprehensive support to meet your needs.',
        },
        {
            question: 'What benefits do real estate consultants provide in Rockhampton?',
            answer: "Real estate consultants offer expert market insights, negotiation skills, and personalized service, helping you make informed decisions and achieve better outcomes in your real estate transactions.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${RealEstateRockampthon})`,
                backgroundPosition: 'center',
            }}>
                 <MetaTags
        title="Real Estate Consulting Service in Rockhampton | Smart Resources QLD"
        description="Unlock Rockhampton's real estate potential with Smart Resources QLD's expert consulting services. Discover market insights, investment opportunities, and personalized guidance."
        keywords="Real Estate Consulting Service in Rockhampton"
        identifyUrl="https://smartresourcesqld.com.au/real-estate-consulting/rockhampton"
        DCTitle="Real Estate Consulting Service in Rockhampton | Smart Resources QLD"
        DCDescription="Unlock Rockhampton's real estate potential with Smart Resources QLD's expert consulting services. Discover market insights, investment opportunities, and personalized guidance."
        DCSubject="Real Estate Consulting Service in Rockhampton"
        Canonical="https://smartresourcesqld.com.au/real-estate-consulting/rockhampton"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate Consulting Service in Rockhampton | Smart Resources QLD"
        ogDescription="Unlock Rockhampton's real estate potential with Smart Resources QLD's expert consulting services. Discover market insights, investment opportunities, and personalized guidance."
        ogUrl="https://smartresourcesqld.com.au/real-estate-consulting/rockhampton"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Real Estate Consulting in Rockhampton</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/real-estate-consulting">Real Estate Consulting</NavLink></li>
                        <li>Rockhampton</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Comprehensive Real Estate Consulting Services in Rockhampton</h1>
                                        <p>Welcome to Smart Resources QLD's Real Estate Consulting services page for Rockhampton. As a central hub in Queensland, Rockhampton presents a range of real estate opportunities. Our team of experts is here to guide you through the market, whether you're buying, selling, or investing.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg real_estate_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                        Contact us today to explore how we can help you succeed in the Rockhampton real estate market!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={RockampthonRealEstate} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Real Estate Consulting in Rockhampton?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Economic Growth:    </strong>Rockhampton's growing economy and infrastructure development make it a promising area for real estate investments. Our consultants will help you navigate these opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Local Market Insights:   </strong> With in-depth knowledge of Rockhampton's real estate market, we provide detailed insights into the best areas and investment prospects.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions: </strong> We offer customized consulting services to meet your specific needs, whether you're looking for residential, commercial, or industrial properties.
                                                    </li>
                                                    <li>
                                                        <strong>Comprehensive Services:  </strong> From market analysis and property valuations to negotiations and deal closures, we provide end-to-end support for all your real estate needs.
                                                    </li>
                                                    {/* <li>
                                                        <strong>Risk Management: </strong> Predict and mitigate potential risks by analyzing historical data and identifying emerging trends.

                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Expert Guidance:   </strong>Our team of experienced professionals provides expert guidance and advice tailored to your specific needs and goals.
                                                    </li>
                                                    <li>
                                                        <strong>Client Satisfaction:  </strong> We are dedicated to achieving the highest level of client satisfaction through personalized service and attention to detail.
                                                    </li>

                                                    <li>
                                                        <strong>Market Expertise:  </strong> Our deep understanding of the Rockhampton market ensures that you receive the most accurate and relevant information.
                                                    </li>
                                                    <li>
                                                        <strong>Innovative Solutions: </strong> We utilize innovative strategies and tools to provide you with the best possible outcomes for your real estate investments.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={RealEstateWhyChooseUs1213} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>




                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageRockhampton;