import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import about1 from './images/h4-about1.webp';
import './Stlye.css';

const AboutUsSection = () => {
    const [animated, setAnimated] = useState(false);
    const [progressValues, setProgressValues] = useState([0, 0, 0]);
    const percentage = 75;
    const percentage1 = 85;
    const percentage2 = 90;

    useEffect(() => {
        const handleScroll = () => {
            const sectionF = document.querySelector('.consultio_content .about_us_section');
            if (!sectionF) return;

            const sectionFTop = sectionF.getBoundingClientRect().top;
            const scrollPosition = window.scrollY + window.innerHeight;

            if (scrollPosition > sectionFTop && !animated) {
                setAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [animated]);

    useEffect(() => {
        // Animate progress bars on component mount
        animateProgressBars();
    }, []);

    const animateProgressBars = () => {
        // Animate progress bars gradually on mount
        const durations = [0, 0, 0]; // Durations in milliseconds for each progress bar animation
        const values = ['Tailored Support for Business Success', 'Empowering Through Innovation', 'Creating Opportunities for Growth']; // Final values for each progress bar

        setTimeout(() => {
            setProgressValues(values);
        }, durations[2]);
    };

    const progressItems = [
        { value: progressValues[0], color: '#ff6347', thickness: 12, label: 'Innovation Impact' },
        { value: progressValues[1], color: '#20b2aa', thickness: 10, label: 'Business Growth' },
        { value: progressValues[2], color: '#9370db', thickness: 8, label: 'Sustainability Support' }
    ];

    return (
        <section className={`about_us_section ${animated ? 'fade-in' : ''}`}>
            <div className="about_us_innr_sec">
                <div className={`about_us_innr_left ${animated ? 'animate' : ''}`}>
                    <div className="about_left_heading">
                        <div className="abt_sub_title">About Us</div>
                        <h3 data-aos="fade-up" aos-anchor-placement="top-center">Discover Our Vision and Values</h3>
                        <p>Discover tailored resources and support to fuel your business success with Smart Resources QLD. Empowering Queensland businesses through innovation and opportunity.</p>
                    </div>
                    
                    <div className="work_development" style={{ display: 'flex', justifyContent: 'center' }}>
                        
                            <div className="work_innr_dev">
                                <CircularProgressbar
                                    //value={item.value}
                                    //text={`${item.value}`}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        lengthAdjust: "spacingAndGlyphs",
                                        strokeLinecap: 'butt',
                                        textSize: '16px',
                                        textLength: "180px",
                                        pathColor: 'rgba(239,130,12,0.8)',
                                        textColor: '#f88',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: 'rgba(0,7,25,0.8)',
                                    })}
                                />
                                <p>Tailored Support for Business Success</p>
                                <span>Innovation Impact</span>
                            </div>
                            <div className="work_innr_dev">
                                <CircularProgressbar
                                    //value={item.value}
                                    //text={`${item.value}`}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        lengthAdjust: "spacingAndGlyphs",
                                        strokeLinecap: 'butt',
                                        textSize: '16px',
                                        textLength: "180px",
                                        pathColor: 'rgba(239,130,12,0.8)',
                                        textColor: '#f88',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: 'rgba(0,7,25,0.8)',
                                    })}
                                />
                                <p>Empowering Through Innovation</p>
                                <span>Business Growth</span>
                            </div>
                            <div className="work_innr_dev">
                                <CircularProgressbar
                                    //value={item.value}
                                    //text={`${item.value}`}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        lengthAdjust: "spacingAndGlyphs",
                                        strokeLinecap: 'butt',
                                        textSize: '16px',
                                        textLength: "180px",
                                        pathColor: 'rgba(239,130,12,0.8)',
                                        textColor: '#f88',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: 'rgba(0,7,25,0.8)',
                                    })}
                                />
                                <p>Creating Opportunities for Growth</p>
                                <span>Sustainability Support</span>
                            </div>

                    </div>
                </div>
                <div className="about_us_innr_right" data-aos="fade-left">
                    <figure className="about_img">
                        <img src={about1} alt="About Us" className="img-fluid" />
                    </figure>
                </div>
            </div>
        </section>
    );
};

export default AboutUsSection;
