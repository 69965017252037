import React, { useState } from 'react';

const ContactSubmitForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        select: ''
    });

    const [message, setMessage] = useState({ text: '', isVisible: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    var btnClicked = false;
    const contactSubmit = async (e) => {
        e.preventDefault();
        btnClicked = true;
        const { name, email, phone, select } = formData;

        const data = new FormData();
        data.append('nm', name);
        data.append('em', email);
        data.append('ph', phone);
        data.append('sel', select);

        try {
            const response = await fetch('https://smartresourcesqld.com.au/contact.php', {
                method: 'POST',
                body: data
            });

            const result = await response.text();

            if (result === 'ok') {
                setMessage({ text: 'Submitted Successfully', isVisible: true });
            } else {
                setMessage({ text: 'Something went wrong! Please try again later.', isVisible: true });
            }

            // Optionally, reset the form
            setFormData({
                name: '',
                email: '',
                phone: '',
                select: ''
            });

        } catch (error) {
            console.error('Error:', error);
            setMessage({ text: 'Error occurred! Please try again later.', isVisible: true });
        }

        // Hide message after 5 seconds
        setTimeout(() => {
            setMessage({ text: '', isVisible: false });
        }, 5000);
    };

    return (
        <form onSubmit={contactSubmit}>
            <div className="row">
                <div className="col-lg-6 col-xl-6 col-md-12">
                    <div className="name_field">
                        <label htmlFor="contact_name">Name</label>
                        <input
                            type="text"
                            id="contact_name"
                            name="name"
                            placeholder="Your Name*"
                            required
                            className="form-control name-control name_text"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-md-12">
                    <div className="name_field">
                        <label htmlFor="contact_email">Email</label>
                        <input
                            type="email"
                            id="contact_email"
                            className="form-control name_text name-control"
                            name="email"
                            placeholder="Your Email*"
                            required
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-md-12">
                    <div className="name_field">
                        <label htmlFor="contact_phone">Phone</label>
                        <input
                            type="tel"
                            id="contact_phone"
                            className="form-control name_text name-control"
                            name="phone"
                            placeholder="Your Phone*"
                            required
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-md-12">
                    <div className="name_field">
                        <label htmlFor="contact_select">Select a subject to get help*</label>
                        <select
                            id="contact_select"
                            className="form-control name_text name-control name_select"
                            name="select"
                            value={formData.select}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Choose an option</option>
                            <option value="Success Fulfill">Success Fulfill</option>
                            <option value="Startup Business">Startup Business</option>
                            <option value="Business Growth">Business Growth</option>
                            <option value="Leadership Work">Leadership Work</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-12">
                    <button type="submit" className="submit_button consult_submit">Submit now</button>
                </div>
                {((btnClicked) && (!message.isVisible)) && (
                    <div className="col-lg-12">
                        <div className="alert alert-info" role="alert">
                            Loading...
                        </div>
                    </div>
                )}
                {message.isVisible && (
                    <div className="col-lg-12">
                        <p className="alert alert-info">
                            {message.text}
                        </p>
                    </div>
                )}
            </div>
        </form>
    );
};

export default ContactSubmitForm;
