import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = ({ 
  title, 
  description, 
  keywords,
  identifyUrl,
  DCTitle,
  DCDescription,
  DCSubject,
  Canonical,
  ogTitle, 
  ogDescription, 
  ogImage, 
  ogUrl 
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow, archive" />
      <meta name="msnbot" content="index, follow, archive" />
      <meta name="Slurp" content="index, follow, archive" />
      <meta name="organization" content="Smart Resources QLD" />
      <meta name="identifier-url" content={identifyUrl} />
      <meta name="DC.Title" content={DCTitle}/>
      <meta name="DC.Description" content={DCDescription} />
      <meta name="DC.Subject" content={DCSubject} />
      <meta name="DC.Language" content="en" />
      <meta name="DC.Creator" content="Smart Resources QLD" />
      <link rel="canonical" href={Canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogUrl} />
    </Helmet>
  );
};

export default MetaTags;
