import React from 'react';
import theme10 from './images/real.webp';
import './Stlye.css';
import { NavLink, Link } from 'react-router-dom';
import theme13 from './images/min.webp';
import theme8 from './images/datablog.webp';
import fu from './images/fu.webp';
import pr from './images/pr.webp';

const NewsFeed = () => {
  return (
    <>
      <section className="news_feed">
        <div className="news_innr_feed">
          <div className="serv_heading">
            <div className="serv_heading_left">
              <div className="about_left_heading wow fadeInLeft">
                <div className="abt_sub_title">Blog</div>
                <h3>Hello & welcome Let us tell you our story!</h3>
              </div>
            </div>
            <div className="serv_heading_right">
              <div className="about_right_heading121 wow fadeInRight">
                <p>
                Stay informed and inspired with our dynamic blog, where industry insights, expert advice, and trending topics converge. Explore the latest trends, strategies, and innovations shaping the business landscape, curated exclusively for you by Smart Resources QLD.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="left_col">
                <div className="grid_item_innr wow fadeIn">
                  <div className="featured_img">
                    <Link to="/post" onClick={() => window.scrollTo(0, 0)}><img src={theme10} alt="Theme 10" /></Link>
                  </div>
                  <div className="featured_body">
                    <h3><Link to="/post" onClick={() => window.scrollTo(0, 0)}>Navigating The Labyrinth: The Crucial Role Of Real Estate Consulting</Link></h3>
                    <ul className="entry-meta"><li className="item-author"><Link to="/post" onClick={() => window.scrollTo(0, 0)}>admin</Link></li><li className="item-date">May 13, 2024</li></ul>
                    <div className="entry-readmore"> <NavLink to="/post" onClick={() => window.scrollTo(0, 0)}>Read more <i className="fa-solid fa-angles-right"></i></NavLink> </div>
                  </div>
                </div>
              </div>

              <div className="left_col">
                <div className="grid_item_innr wow fadeIn">
                  <div className="featured_img">
                    <Link to="/fulepost" onClick={() => window.scrollTo(0, 0)}><img src={fu} alt="FU" /></Link>
                  </div>
                  <div className="featured_body">
                    <h3><Link to="/fulepost" onClick={() => window.scrollTo(0, 0)}>Wings Of Opportunity: The Role Of Angel Investors In Fueling Innovation</Link></h3>
                    <ul className="entry-meta"><li className="item-author"><Link to="/fulepost" onClick={() => window.scrollTo(0, 0)}>admin</Link></li><li className="item-date">May 13, 2024</li></ul>
                    <div className="entry-readmore"> <NavLink to="/fulepost" onClick={() => window.scrollTo(0, 0)}>Read more <i className="fa-solid fa-angles-right"></i></NavLink> </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left_col">
                <div className="grid_item_innr wow fadeIn">
                  <div className="featured_img">
                    <Link to="/datapost" onClick={() => window.scrollTo(0, 0)}><img src={theme8} alt="Theme 8" /></Link>
                  </div>
                  <div className="featured_body">
                    <h3><Link to="/datapost" onClick={() => window.scrollTo(0, 0)}>Data Alchemy: Unveiling The Magic Of Data Consulting</Link></h3>
                    <ul className="entry-meta"><li className="item-author"><Link to="/datapost" onClick={() => window.scrollTo(0, 0)}>admin</Link></li><li className="item-date">May 13, 2024</li></ul>
                    <div className="entry-readmore"> <NavLink to="/datapost" onClick={() => window.scrollTo(0, 0)}>Read more <i className="fa-solid fa-angles-right"></i></NavLink> </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="left_col">
                <div className="grid_item_innr wow fadeIn">
                  <div className="featured_img">
                    <Link to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}><img src={pr} alt="PR" /></Link>
                  </div>
                  <div className="featured_body">
                    <h3><Link to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>Fueling Success: The Power Of Petroleum Consulting</Link></h3>
                    <ul className="entry-meta"><li className="item-author"><Link to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>admin</Link></li><li className="item-date">May 13, 2024</li></ul>
                    <div className="entry-readmore"> <NavLink to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>Read more <i className="fa-solid fa-angles-right"></i></NavLink> </div>
                  </div>
                </div>
              </div>

              <div className="left_col">
                <div className="grid_item_innr wow fadeIn">
                  <div className="featured_img">
                    <Link to="/miningpost" onClick={() => window.scrollTo(0, 0)}><img src={theme13} alt="Theme 13" /></Link>
                  </div>
                  <div className="featured_body">
                    <h3><Link to="/miningpost" onClick={() => window.scrollTo(0, 0)}>Unearthing Opportunities: The Essential Role Of Mining Consulting</Link></h3>
                    <ul className="entry-meta"><li className="item-author"><Link to="/miningpost" onClick={() => window.scrollTo(0, 0)}>admin</Link></li><li className="item-date">May 13, 2024</li></ul>
                    <div className="entry-readmore"> <NavLink to="/miningpost" onClick={() => window.scrollTo(0, 0)}>Read more <i className="fa-solid fa-angles-right"></i></NavLink> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsFeed;
