import React, { useState } from 'react';
import './App.css'; // Assuming you have CSS classes defined here

const App = () => {
  const [hoveredDiv, setHoveredDiv] = useState(0);

  const handleMouseEnter = (index) => {
    setHoveredDiv(index);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  return (
    <div className="container">
      <div
        className={`box ${hoveredDiv === 0 ? 'hovered' : ''}`}
        onMouseEnter={() => handleMouseEnter(0)}
        onMouseLeave={handleMouseLeave}
        style={{margin:"100px 0 0 0"}}
      >
        Div 1
      </div>
      <div
        className={`box ${hoveredDiv === 1 ? 'hovered' : ''}`}
        onMouseEnter={() => handleMouseEnter(1)}
        onMouseLeave={handleMouseLeave}
        style={{margin:"100px 0 0 0"}}
      >
        Div 2
      </div>
      <div
        className={`box ${hoveredDiv === 2 ? 'hovered' : ''}`}
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={handleMouseLeave}
        style={{margin:"100px 0 0 0"}}
      >
        Div 3
      </div>
      <div
        className={`box ${hoveredDiv === 3 ? 'hovered' : ''}`}
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={handleMouseLeave}
        style={{margin:"100px 0 0 0"}}
      >
        Div 4
      </div>
    </div>
  );
};

export default App;
