import React,{ useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import PetroliumBannerMiddle from "../images/petrolium_middle_banner.webp";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Petroleumbanner from '../images/petroleum-banner.webp';
import faq from '../images/frequently-asked-questions-solution-concept.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from '../MetaTags';
import '../Stlye.css';
const Realestateconsulting = () => {
  const [openIndex, setOpenIndex] = useState(null);
      const faqData = [
        {
          question: 'What is petroleum consulting?',
          answer: 'Petroleum consulting involves providing expert advice and support to companies involved in the exploration, production, and development of oil and gas resources. Consultants offer specialized services to help clients optimize operations, mitigate risks, and achieve strategic objectives.',
        },
        {
          question: 'Why is petroleum consulting important?',
          answer: 'Petroleum consulting plays a crucial role in helping companies navigate the complex challenges of the oil and gas industry. Consultants bring specialized knowledge and experience to address technical, regulatory, and economic factors, ultimately enhancing project outcomes and maximizing returns on investment.',
        },
        {
          question: 'What services do petroleum consultants offer?',
          answer: 'Petroleum consultants offer a wide range of services, including reservoir engineering, field development planning, risk assessment, regulatory compliance, and project management support. These services are tailored to meet the specific needs of clients and address key challenges throughout the project lifecycle.',
        },
        {
          "question": "How do I choose the right petroleum consulting firm?",
          "answer": "When selecting a petroleum consulting firm, it's essential to consider factors such as the firm's experience, expertise, track record, and approach to client collaboration. It's also important to evaluate the firm's understanding of local regulations, market dynamics, and emerging trends in the oil and gas industry."
        },
        {
          question: 'What are the benefits of hiring a petroleum consultant?',
          answer: 'Hiring a petroleum consultant offers several benefits, including access to specialized expertise, objective insights, and tailored solutions. Consultants help clients optimize operations, minimize risks, and maximize the value of their petroleum assets, ultimately enhancing profitability and competitiveness.',
        },
        // Add more FAQ items as needed
      ];
      const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
  return (
    <div>
       <MetaTags
        title="Petroleum Consulting Service in Queensland, Australia | Smart Resources QLD"
        description="Navigate Queensland's petroleum industry with confidence. Smart Resources QLD's consulting services offer expert guidance for exploration, development & production."
        keywords="Petroleum Consulting Service in Queensland, Australia"
        identifyUrl="https://smartresourcesqld.com.au/petroleum-consulting"
        DCTitle="Petroleum Consulting Service in Queensland, Australia | Smart Resources QLD"
        DCDescription="Navigate Queensland's petroleum industry with confidence. Smart Resources QLD's consulting services offer expert guidance for exploration, development & production."
        DCSubject="Petroleum Consulting Service in Queensland, Australia"
        Canonical="https://smartresourcesqld.com.au/petroleum-consulting"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Petroleum Consulting Service in Queensland, Australia | Smart Resources QLD"
        ogDescription="Navigate Queensland's petroleum industry with confidence. Smart Resources QLD's consulting services offer expert guidance for exploration, development & production."
        ogUrl="https://smartresourcesqld.com.au/petroleum-consulting"
      />
      <section className="innr_banner" style={{ 
      backgroundImage: `url(${Petroleumbanner})` }}>
        <div className="page_title_innr">
          <div className="page_title">       Petroleum Consulting Services </div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/our-operations">Services</NavLink></li>
            <li>Petroleum Consulting Services</li>
          </ul>
        </div>
      </section>
      <section className="pricing servinnrbg">
        <div className="servinnng_middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="global_left serv_innr_heading">
                  <h1> Petroleum Consulting Services </h1>
                  <p>Welcome to Smart Resources QLD, your trusted partner for comprehensive petroleum consulting services in Queensland. With a deep understanding of the oil and gas industry, we offer expert guidance and solutions tailored to meet the unique needs of our clients.<br/>
<div className="col-lg-6 col-md-6 col-sm-12">
             
              </div></p>
              
                </div>
              </div>
              
            
            </div>
          </div>
        </div>
        <div className="discuss-seo seo_price_bg">
        <div className="container">
      <div className="row discuss-ppc-seo">
        <h2>Ready to Navigate the Petroleum Industry?</h2>
        <div className="button-ppc-new">
          <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
          {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
        </div>
      </div>
    </div>
</div>

        <section  className="include_services">
        <div>
        <Container>
        <Row>

        <Col lg="4" md="6" sm="12">
            <div className='include_img'><img src={PetroliumBannerMiddle} alt=" " className="img-fluid" /></div>
        </Col>

        <Col lg="8" md="6" sm="12">
        <div className='include_text'>
          <h3>Our Petroleum Consulting Services:</h3>
          {/* <img src={line} alt="Your Image" /> */}
          <div className='pf'>
          <ul>
            <li>
              <strong>Exploration and Production Support:</strong> Our team of experienced consultants provides valuable insights and support for exploration and production activities. From reservoir analysis to drilling optimization, we help clients maximize their resources and achieve operational efficiency.
            </li>
            <li>
              <strong>Reservoir Engineering: </strong>Utilizing advanced reservoir engineering techniques, we assist clients in optimizing reservoir performance, enhancing recovery rates, and maximizing asset value. Our expertise covers conventional and unconventional reservoirs across various geological settings.
            </li>
            <li>
              <strong>Field Development Planning:</strong>We offer comprehensive field development planning services, including feasibility studies, conceptual design, and project management support. Our goal is to help clients navigate complex challenges and execute successful development projects.
            </li>
            <li>
              <strong>Risk Assessment and Management:</strong>We conduct thorough risk assessments to identify potential hazards and uncertainties associated with petroleum projects. Our proactive approach to risk management helps clients mitigate threats and minimize project disruptions.
            </li>
            <li>
              <strong>Regulatory Compliance:</strong>Navigating regulatory requirements is essential for the success of any petroleum project. Our consultants are well-versed in local regulations and ensure compliance throughout the project lifecycle, from permitting to environmental assessments.

            </li>
          </ul>
          </div>
        </div>
        </Col>

  </Row>
  <p className='f'>At Smart Resources QLD, we are committed to delivering innovative solutions that drive value and foster sustainable growth in the petroleum sector. Whether you're an operator, investor, or government agency, we have the expertise and resources to support your objectives.</p>


  <p>We also provide our Petroleum Consulting services in <NavLink to="/petroleum-consulting/cairns" onClick={() => window.scrollTo(0, 0)}>Cairns</NavLink>, <NavLink to="/petroleum-consulting/rockhampton" onClick={() => window.scrollTo(0, 0)}>Rockhampton</NavLink> and <NavLink to="/petroleum-consulting/townsville" onClick={() => window.scrollTo(0, 0)}>Townsville</NavLink></p>
</Container>


    </div>
    <div className="faqContainer">
      <Container>
      <Row>
          <Col lg="8" md="6" sm="12" className='accordion_left'>
        <div className="accordion">
        <h1>Frequently Asked Questions</h1>
          {faqData.map((item, index) => (
            <div key={index} className="accordion-item">
              <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                <h2>{item.question}</h2>
                {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
              {openIndex === index && <div className="accordion-answer">{item.answer}</div>}
            </div>
          ))}
        </div>
        </Col>
        <Col lg="4" md="6" sm="12" className='accordion_right'>
      <div className="faq_Image">
        {/* Place your image here */}
      <img
        src={faq}
        alt=""
        className='img-fluid'
      />
      </div>
      </Col>
      </Row>
      </Container>
    </div>

          {/* <div className="custom_resoueces_arrow">
          <div className="pp89">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
          <div className="nn89">
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div> */}
        </section>
        
      </section>
    </div>
  );
};

export default Realestateconsulting ;
