import React, { useState } from 'react';
import Petroleumbanner from '../images/realpost.webp';
import bannerImage from '../images/realpost1.webp';
import bannerImage1 from '../images/datapost.webp';
import bannerImage2 from '../images/fulepost1.webp';
import bannerImage3 from '../images/mingpost1.webp';
import bannerImage4 from '../images/prpost1.webp';
import emailjs from '@emailjs/browser';
import './Blogstyle.css';
import MetaTags from '../MetaTags';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
// import {}
const BlogPost = () => {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('service_ofw3ypm', 'template_ki9qfe5', e.target, 'II-fnB7G49fYlfyTr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // Reset form fields
        setComment('');
        setName('');
        setEmail('');
        setWebsite('');
    };
    const styles = {
        container: {

            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Arial, sans-serif',
        },
        banner: {
            position: 'relative',
            backgroundColor: '#333',
            color: '#fff',
            // padding: '20px',
            width: '100%',
            zIndex: '1',
            textAlign: 'center',
            position: 'relative',
        },
        bannerOverlay: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.52)',
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
        },


        bannerBlogHeading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#fff',
            fontSize: '40px',

        },
        bannerImage: {
            // maxWidth: '100%',
            // maxHeight: '300px', // Adjust the height as needed
            width: '100%',
            height: '420px',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        itemContainer: {
            marginTop: "35px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            // maxWidth: '100%',
            margin: '0 auto',
        },
        // itemRow: {
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   width: '100%',
        // },

        breadCrumb: {
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        item: {
            position: 'relative',
            // width: '296px',
            // height: '180px',
            backgroundColor: '#eee',
            border: '1px solid #ccc',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px',
            textDecoration: 'none',
            color: '#333',
            transition: 'transform 0.3s ease',
            overflow: 'hidden', // Hide overflowing content
        },
        itemTitle: {
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '5px',
            opacity: 0,
            transition: 'opacity 0.3s ease',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            textAlign: 'center',
            width: '100%',
            padding: '0 10px',
            boxSizing: 'border-box',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
        },
        itemDetails: {
            fontSize: '14px',
            opacity: 0,
            transition: 'opacity 0.3s ease',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(calc(-50% - 20px))', // Adjust position to accommodate title
            textAlign: 'center',
            width: '100%',
            padding: '0 10px',
            boxSizing: 'border-box',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
        },
        itemImage: {
            width: '100%',
            height: '300px',
            transition: 'transform 0.3s ease',
            objectFit: 'cover',
            objectPosition: 'center top',
        },
        // Different styles for item1, item2, item3
        item123: {
            backgroundColor: '#f0f0f0',
            color: '#666',
        },
        // Different styles for item4
        item4: {
            backgroundColor: '#ddd',
            color: '#444',
            marginLeft: "150px"
        },
        // Different styles for item5
        item5: {
            backgroundColor: '#ccc',
            color: '#333',
            marginRight: "150px"
        },




    };
    return (

        <>

            <div style={styles.banner} className='Banner_blog'>
            <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        description="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        identifyUrl="https://smartresourcesqld.com.au/post"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        DCDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        Canonical="https://smartresourcesqld.com.au/post"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        ogDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors"
        ogUrl="https://smartresourcesqld.com.au/post"
      />
                {/* <h1>Website Banner</h1> */}
                <img src={bannerImage} alt="Banner" style={styles.bannerImage} />
                <div style={styles.bannerOverlay}></div>
                <div style={styles.bannerBlogHeading}>Blog</div>
                <ul className="bread_crumb" style={styles.breadCrumb}><li><a href="/">Home</a></li><li>Real Estate Post</li></ul>
            </div>

            <section className="work_safety abt_work_safety blog_partts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8">
                            <div className="blog_left_inner">
                                <h1>Navigating the Labyrinth: The Crucial Role Of Real Estate Consulting</h1>
                                <div>
                                    <figure>
                                        <img src={Petroleumbanner} />
                                    </figure>
                                    <div className="commnt_sec">

                                    </div>
                                </div>
                                <div className="blog_para">

                                    <div className="blog_innr_para">

                                        <p>In the ever-evolving landscape of real estate, the role of consultants has become increasingly vital. From market analysis to investment strategies, these experts provide invaluable guidance to individuals and businesses alike. Let's delve into the world of real estate consulting to understand its significance and how it can benefit you.</p>
                                        <label>Market Insight and Analysis:</label>
                                        <ul>
                                            <li style={{ textAlign: 'justify' }}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>In the ever-evolving landscape of real estate, the role of consultants has become increasingly vital. From market analysis to investment strategies, these experts provide invaluable guidance to individuals and businesses alike. Let's delve into the world of real estate consulting to understand its significance and how it can benefit you.</li>
                                            <label>Strategic Planning and Investment:</label>
                                            <li><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>
                                                Navigating the complex terrain of real estate investment requires careful planning and foresight. Consultants offer strategic advice tailored to your objectives, whether it's portfolio diversification, asset optimization, or risk management. By devising bespoke investment strategies, they empower clients to achieve their financial goals while mitigating potential pitfalls.</li>

                                            <label>Property Valuation and Due Diligence:</label>
                                            <li style={{ textAlign: 'justify' }}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Determining the true value of a property is essential for making sound investment choices. Real estate consultants leverage their expertise and industry knowledge to conduct thorough valuations and due diligence assessments. From assessing market comparables to scrutinizing property condition and legal documentation, they ensure that clients have a comprehensive understanding of the assets they intend to acquire or sell.
                                            </li>





                                            <label>Transaction Management and Negotiation:</label>
                                            <li style={{ textAlign: 'justify' }}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Navigating the intricacies of real estate transactions can be daunting without expert guidance. Consultants serve as trusted advisors throughout the entire process, from initial negotiations to closing. With their negotiation skills and market acumen, they strive to secure favorable terms and maximize value for their clients.
                                            </li>



                                            <label>Risk Mitigation and Compliance:</label>
                                            <li style={{ textAlign: 'justify' }}><i className="kw_wastii flaticon-comment tm-skincolor" aria-hidden="true"></i>Real estate transactions entail various risks, ranging from regulatory compliance issues to unforeseen market fluctuations. Consultants proactively identify and mitigate these risks, safeguarding clients' interests and ensuring regulatory compliance. Whether it's navigating zoning laws, environmental regulations, or contractual obligations, they provide comprehensive risk management solutions.

                                                In conclusion, real estate consulting plays a pivotal role in today's dynamic property market. By harnessing their expertise, insights, and strategic guidance, individuals and businesses can navigate the complexities of real estate transactions with confidence. Whether you're a buyer, seller, investor, or developer, partnering with a reputable consultant can make all the difference in achieving your objectives and maximizing returns on your investment.
                                            </li>

                                        </ul>

                                    </div>
                                    {/* Other content omitted for brevity */}
                                </div>
                                {/* Other content omitted for brevity */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <div className="blog_right_innr">
                                <div className="blog_cat post_cat">
                                    <h5>Recent Posts</h5>
                                    <ul className="post_img">
                                        <li>
                                            <figure>
                                                <img src={bannerImage} />
                                            </figure>
                                            <div className="post_text">
                                                <h4><NavLink to="/post" onClick={() => window.scrollTo(0, 0)}>Real Estate Post</NavLink></h4>

                                                <div className="posted_on">
                                                    <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                                </div>


                                            </div>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={bannerImage3} />
                                            </figure>
                                            <div className="post_text">
                                                <h4><NavLink to="/miningpost" onClick={() => window.scrollTo(0, 0)}>Mining Post</NavLink></h4>

                                                <div className="posted_on">
                                                    <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={bannerImage4} />
                                            </figure>
                                            <div className="post_text">
                                                <h4><NavLink to="/petroleumpost" onClick={() => window.scrollTo(0, 0)}>Petroleum Post</NavLink></h4>

                                                <div className="posted_on">
                                                    <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <figure>
                                                <img src={bannerImage1} />
                                            </figure>
                                            <div className="post_text">
                                                <h4><NavLink to="/datapost" onClick={() => window.scrollTo(0, 0)}>Data Post</NavLink></h4>

                                                <div className="posted_on">
                                                    <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <figure>
                                                <img src={bannerImage2} />
                                            </figure>
                                            <div className="post_text">
                                                <h4><NavLink to="/fulepost" onClick={() => window.scrollTo(0, 0)}> Fuel Post</NavLink></h4>

                                                <div className="posted_on">

                                                    <i className="fa-regular fa-calendar-days"></i> May 13, 2024
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="leave_a_reply">
                                <h4>Leave A Reply</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control text_partts"
                                            placeholder="Comments"
                                            name="comment"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control reply_text text_partts1213"
                                            placeholder="Name (required)"
                                            name="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control reply_text text_partts1213 "
                                            placeholder="Email (required)"
                                            name="email"
                                            style={{ float: 'right' }}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <input
                                        name="submit"
                                        type="submit"
                                        id="submit"
                                        className="submit"
                                        value="Post Comment"
                                    />
                                </form>
                            </div>
                        </Col>
                    </Row>

                </div>

            </section>


        </>
    );
}

export default BlogPost;
