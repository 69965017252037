import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner3 from '../src/images/rockamphton_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
import Data226 from "../src/images/middle_img.webp";
// import Data224 from "../src/images/data_223.jpg";
// import Data225 from "../src/images/data_225.jpg";
import Data121348 from "../src/images/last_banner.webp";

import PetroleumCons from "../src/images/petroleum-cons.webp";
import PetLeft from "../src/images/petroleam_cons.webp";
import PetRight from "../src/images/pet_right_right.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageRockhampton() {


    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why is Rockhampton an ideal location for petroleum operations?',
            answer: 'Rockhampton`s central location and robust infrastructure make it a strategic base for petroleum activities throughout Central Queensland.',
        },
        {
            question: 'How can your consulting services benefit my petroleum operations?',
            answer: "Our consulting services provide expert guidance, strategic planning, and operational support to optimize efficiency, sustainability, and profitability in your petroleum operations.",
        },
        {
            question: 'What types of petroleum projects do you support in Rockhampton?',
            answer: "We support a variety of projects, including exploration, production, refining, and distribution operations across various petroleum products.",
        },
        {
            question: 'How do you ensure regulatory compliance for my operations?',
            answer: 'We stay up-to-date with all relevant regulations and work closely with you to ensure your operations meet all local, state, and federal compliance requirements.',
        },
        {
            question: 'What sustainable practices do you recommend for petroleum operations?',
            answer: "We recommend practices such as efficient resource utilization, waste reduction, and environmental impact mitigation to ensure responsible and sustainable petroleum operations.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${PetroleumCons})`,
                backgroundPosition: 'bottom'
            }}>
                 <MetaTags
        title="Petroleum Consulting Service in Rockhampton | Smart Resources QLD"
        description="Fuel your Rockhampton petroleum success with Smart Resources QLD. Expert guidance for exploration, drilling & maximizing resource recovery."
        keywords="Petroleum Consulting Service in Rockhampton"
        identifyUrl="https://smartresourcesqld.com.au/petroleum-consulting/rockhampton"
        DCTitle="Petroleum Consulting Service in Rockhampton | Smart Resources QLD"
        DCDescription="Fuel your Rockhampton petroleum success with Smart Resources QLD. Expert guidance for exploration, drilling & maximizing resource recovery."
        DCSubject="Petroleum Consulting Service in Rockhampton"
        Canonical="https://smartresourcesqld.com.au/petroleum-consulting/rockhampton"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Petroleum Consulting Service in Rockhampton | Smart Resources QLD"
        ogDescription="Fuel your Rockhampton petroleum success with Smart Resources QLD. Expert guidance for exploration, drilling & maximizing resource recovery."
        ogUrl="https://smartresourcesqld.com.au/petroleum-consulting/rockhampton"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Petroleum Consulting in Rockhampton</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/petroleum-consulting">Petroleum Consulting</NavLink></li>
                        <li>Rockhampton</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Comprehensive Petroleum Consulting Services in Rockhampton </h1>
                                        <p>Welcome to Smart Resources QLD's Petroleum Consulting services page for Rockhampton. As a central hub for petroleum activities in Central Queensland, Rockhampton offers extensive opportunities for petroleum operations. Our expert team provides comprehensive consulting services to help you optimize your projects, ensuring efficiency, sustainability, and regulatory compliance.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg pet_banner_small">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                Contact us today to discover how we can enhance your petroleum operations in Rockhampton!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={PetLeft} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Petroleum Consulting in Rockhampton? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Central Location:    </strong>Rockhampton's central location makes it a strategic base for petroleum operations throughout Central Queensland. Our consultants can help you leverage this advantage.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:    </strong> With in-depth knowledge of Rockhampton's petroleum sector, we provide insights and strategies tailored to the region's specific needs and opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Sustainable Operations:</strong> We emphasize sustainable and environmentally responsible practices, helping you minimize impact while maximizing operational efficiency.
                                                    </li>
                                                    <li>
                                                        <strong>Regulatory Compliance:  </strong> Our team ensures your operations comply with all necessary regulations, helping you avoid compliance issues and operational disruptions.
                                                    </li>
                                                    <li>
                                                        <strong>Advanced Techniques:  </strong> We utilize the latest technologies and best practices in petroleum engineering and project management to deliver innovative solutions.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Skilled Professionals:   </strong>Our team of experienced petroleum consultants brings a wealth of industry knowledge and local expertise to your projects.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions:  </strong> We provide customized consulting services to meet your unique needs, whether you're starting a new project or optimizing an existing one.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Success:  </strong> Our successful track record in the Rockhampton petroleum sector demonstrates our ability to deliver results and add value to your operations.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Focused Approach: </strong> We prioritize your goals and work collaboratively to develop strategies that align with your business objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={PetRight} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>




                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageRockhampton;