import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner1 from '../src/images/data--banner--02.webp';
import MiningConsTowns from "../src/images/mining_cons_towns.webp";
import Petroleumbanner from '../src/images/data-banner.webp';
import DataCons1213 from "../src/images/data_cons_1213.webp";
import Data222 from "../src/images/data_222.webp";
import Data223 from "../src/images/data_223.webp";
import Data121345 from "../src/images/data__121345.webp";
import faq1 from '../src/images/faq_banner.webp';
import MiningConsTownsVille from "../src/images/mining_cons_towns.webp";
import MiningConsTownsVilleRight from "../src/images/mining_consss_right.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageTownsVille() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'What makes Townsville a strategic location for mining operations?',
            answer: 'Townsville`s proximity to major mining sites and its established infrastructure make it an ideal base for mining operations in North Queensland.',
        },
        {
            question: 'How can mining consulting services benefit my operations?',
            answer: "Mining consulting services provide expert insights, strategic planning, and operational support to optimize your mining activities, ensuring efficiency and profitability.",
        },
        {
            question: 'What types of mining projects do you support in Townsville?',
            answer: "We support a wide range of mining projects, including mineral exploration, extraction, and processing operations across various commodities.",
        },
        {
            question: 'How do you ensure regulatory compliance in mining operations?',
            answer: 'Our team stays up-to-date with the latest regulations and works closely with you to ensure that all aspects of your mining operations comply with local, state, and federal laws.',
        },
        {
            question: 'What sustainable practices do you recommend for mining operations?',
            answer: "We recommend practices such as waste reduction, resource efficiency, and environmental impact mitigation to ensure sustainable mining operations.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${MiningConsTowns})`
            }}>
                 <MetaTags
        title="Mining Consulting Service in Townsville | Smart Resources QLD"
        description="Streamline your Townsville mining project with Smart Resources QLD's expert consulting. We guide exploration, permitting, & operations for success."
        keywords="Mining Consulting Service in Townsville"
        identifyUrl="https://smartresourcesqld.com.au/mining-consulting/townsville"
        DCTitle="Mining Consulting Service in Townsville | Smart Resources QLD"
        DCDescription="Streamline your Townsville mining project with Smart Resources QLD's expert consulting. We guide exploration, permitting, & operations for success."
        DCSubject="Mining Consulting Service in Townsville"
        Canonical="https://smartresourcesqld.com.au/mining-consulting/townsville"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Mining Consulting Service in Townsville | Smart Resources QLD"
        ogDescription="Streamline your Townsville mining project with Smart Resources QLD's expert consulting. We guide exploration, permitting, & operations for success."
        ogUrl="https://smartresourcesqld.com.au/mining-consulting/townsville"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Mining Consulting in Townsville</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/mining-consulting">Mining Consulting</NavLink></li>
                        <li>Townsville</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts '>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Expert Mining Consulting Services in Townsville</h1>
                                        <p>Welcome to Smart Resources QLD's Mining Consulting services page for Townsville. As a pivotal location in North Queensland's mining industry, Townsville offers abundant opportunities for mining operations. Our expert team provides comprehensive consulting services tailored to optimize your mining activities, ensuring efficiency, sustainability, and profitability.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg mining_short_image">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Contact us today to learn how we can enhance your mining operations in <strong>Townsville!</strong>
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={MiningConsTownsVille} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Mining Consulting in Townsville?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Strategic Location:   </strong>Townsville's proximity to major mining sites makes it a crucial hub for mining operations in North Queensland. Our consultants can help you leverage this strategic advantage.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:    </strong> With extensive knowledge of the local mining landscape, we provide insights and strategies that are specifically tailored to the region's unique challenges and opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Sustainable Practices:  </strong> We emphasize sustainable mining practices, helping you minimize environmental impact while maximizing resource extraction.
                                                    </li>
                                                    <li>
                                                        <strong>Regulatory Compliance:  </strong> Navigating the complex regulatory landscape of mining can be challenging. Our team ensures that your operations comply with all local, state, and federal regulations.
                                                    </li>
                                                    <li>
                                                        <strong>Advanced Technology: </strong> We utilize the latest technologies and methodologies in mining engineering and project management to deliver innovative solutions.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Experienced Professionals:   </strong> Our team consists of experienced mining consultants with a deep understanding of the industry and local market conditions.
                                                    </li>
                                                    <li>
                                                        <strong>Tailored Solutions: </strong> We provide customized consulting services to meet your specific needs, whether you're starting a new project or optimizing an existing operation.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Track Record:  </strong> Our successful track record in the Townsville mining sector speaks to our ability to deliver results and add value to your operations.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Focused Approach: </strong> We prioritize your goals and work closely with you to develop strategies that align with your objectives.
                                                    </li>
                                                    {/* <li>
                                                        <strong>Local Expertise: </strong> Our team is well-versed in the specific economic landscape and industry trends of Townsville, ensuring relevant and impactful data solutions.
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={MiningConsTownsVilleRight} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageTownsVille;