import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner2 from '../src/images/cairns_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
// import Data222 from "../src/images/data_222.jpg";
// import Data224 from "../src/images/data_223.jpg";
import Data225 from "../src/images/data_225.webp";
import Data121346 from "../src/images/data__121346.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageCairns() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'How can data science benefit the tourism industry in Cairns?',
            answer: 'Data science can help tourism businesses understand visitor patterns, optimize pricing strategies, personalize marketing campaigns, and improve overall guest experiences, leading to increased satisfaction and repeat visits.',
        },
        {
            question: 'What environmental applications does data science have in Cairns?',
            answer: "Data science can be used for environmental monitoring, predicting weather patterns, managing natural resources, and supporting conservation efforts, helping to protect Cairns' unique ecosystems.",
        },
        {
            question: 'How does data science improve healthcare services in Cairns?',
            answer: "In healthcare, data science can enhance patient care by predicting disease outbreaks, personalizing treatment plans, optimizing resource allocation, and improving operational efficiency.",
        },
        {
            question: 'What types of data are useful for data science projects in Cairns?',
            answer: 'Useful data includes customer feedback, sales records, environmental data, social media interactions, operational logs, and more. Our team helps identify and prepare the most relevant data for analysis.',
        },
        {
            question: 'How quickly can businesses in Cairns see results from data science initiatives?',
            answer: "The timeline varies depending on the project's scope and complexity, but many businesses begin to see valuable insights and improvements within a few months of implementation.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${Petroleumbanner2})`
            }}>
                 <MetaTags
        title="Data Science Service Cairns | Smart Resources QLD"
        description="Unlock data-driven insights for your Cairns, Queensland business with Smart Resources QLD's expert data science services."
        keywords="Data Science Service Cairns"
        identifyUrl="https://smartresourcesqld.com.au/data-science/cairns"
        DCTitle="Data Science Service Cairns | Smart Resources QLD"
        DCDescription="Unlock data-driven insights for your Cairns, Queensland business with Smart Resources QLD's expert data science services."
        DCSubject="Data Science Service Cairns"
        Canonical="https://smartresourcesqld.com.au/data-science/cairns"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Data Science Service Cairns | Smart Resources QLD"
        ogDescription="Unlock data-driven insights for your Cairns, Queensland business with Smart Resources QLD's expert data science services."
        ogUrl="https://smartresourcesqld.com.au/data-science/cairns"
      />

        <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
        </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Data Science Service in Cairns</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/data-science">Data Science</NavLink></li>
                        <li>Cairns</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Harness the Power of Data Science in Cairns</h1>
                                        <p>Welcome to Smart Resources QLD's Data Science services page for Cairns. As a key economic and cultural hub in Far North Queensland, Cairns is well-positioned to benefit from advanced data science solutions. Our expert team is dedicated to helping local businesses and organizations leverage data to drive innovation, efficiency, and growth.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Unlock the potential of your business with our data science services
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={Data225} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Benefits of Data Science for Cairns Businesses </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Enhanced Customer Experience:   </strong>Gain insights into customer behavior and preferences to tailor services and improve satisfaction.
                                                    </li>
                                                    <li>
                                                        <strong>Operational Efficiency:   </strong> Identify inefficiencies and streamline processes to reduce costs and enhance productivity.
                                                    </li>
                                                    <li>
                                                        <strong>Environmental Monitoring: </strong>  Use data to track and manage environmental factors, supporting conservation efforts and sustainable practices.
                                                    </li>
                                                    <li>
                                                        <strong>Improved Decision Making: </strong> Leverage data-driven insights to make informed strategic decisions and stay competitive.
                                                    </li>
                                                    <li>
                                                        <strong>Risk Management: </strong> Predict and mitigate potential risks by analyzing historical data and identifying emerging trends.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Data Science in Cairns? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Tourism and Hospitality Focus:  </strong>Cairns is renowned for its vibrant tourism industry. Data science can help businesses in this sector better understand customer preferences, optimize marketing strategies, and enhance visitor experiences.
                                                    </li>
                                                    <li>
                                                        <strong>Environmental Sustainability:  </strong> With its proximity to the Great Barrier Reef and lush rainforests, Cairns is at the forefront of environmental conservation. Data science enables better monitoring and management of natural resources, supporting sustainable practices.
                                                    </li>

                                                    <li>
                                                        <strong>Healthcare and Education: </strong> Cairns boasts a growing healthcare and education sector. Data science can improve patient outcomes, streamline operations, and enhance educational programs through predictive analytics and personalized learning solutions.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise: </strong> Our team combines technical prowess with a deep understanding of Cairns' unique market dynamics, ensuring that our solutions are both innovative and locally relevant.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={Data121346} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>



                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageCairns;