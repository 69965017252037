import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner2 from '../src/images/cairns_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
// import Data222 from "../src/images/data_222.jpg";
// import Data224 from "../src/images/data_223.jpg";
import Data225 from "../src/images/data_225.webp";
import Data121346 from "../src/images/data__121346.webp";
import faq1 from '../src/images/faq_banner.webp';
import AngelCairnsBg from "../src/images/angel_cairns_bg.webp";
import Angel1 from "../src/images/angel_1.webp";
import WhyChhoseBg from "../src/images/why_choose_bg.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageCairns() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'What are the benefits of working with an angel investor?',
            answer: 'Angel investors provide not only funding but also mentorship, industry connections, and strategic advice, which can be invaluable for the growth of your startup.',
        },
        {
            question: 'How can your consulting services increase my chances of securing investment?',
            answer: "We help you develop a strong business plan and pitch deck, and connect you with our network of investors, enhancing your appeal and chances of securing funding.",
        },
        {
            question: 'What types of startups are most attractive to angel investors in Cairns?',
            answer: "Startups with innovative solutions, scalable business models, and strong market potential are particularly attractive to angel investors in Cairns.",
        },
        {
            question: 'What should I include in my pitch to angel investors?',
            answer: 'Your pitch should include a clear business plan, market analysis, financial projections, and a compelling value proposition that highlights your startup’s potential.',
        },
        {
            question: 'What is the typical timeline for securing angel investment?',
            answer: "The timeline can vary but generally involves preparing your pitch, presenting to investors, undergoing due diligence, and finalizing the investment agreement over a period of several months.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${AngelCairnsBg})`,
                backgroundPosition:'bottom'
            }}>
                 <MetaTags
        title="Angel Investor Service Cairns | Smart Resources QLD"
        description="Secure Cairns, Queensland angel investment with Smart Resources QLD's consulting. Strengthen your pitch & attract the right investors for your startup."
        keywords="Angel Investor Service Cairns"
        identifyUrl="https://smartresourcesqld.com.au/angel-investor/cairns"
        DCTitle="Angel Investor Service Cairns | Smart Resources QLD"
        DCDescription="Secure Cairns, Queensland angel investment with Smart Resources QLD's consulting. Strengthen your pitch & attract the right investors for your startup."
        DCSubject="Angel Investor Service Cairns"
        Canonical="https://smartresourcesqld.com.au/angel-investor/cairns"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Angel Investor Service Cairns | Smart Resources QLD"
        ogDescription="Secure Cairns, Queensland angel investment with Smart Resources QLD's consulting. Strengthen your pitch & attract the right investors for your startup."
        ogUrl="https://smartresourcesqld.com.au/angel-investor/cairns"
      />

        <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
        </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Angel Investor Consulting in Cairns</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/angel-investor">Angel Investor</NavLink></li>
                        <li>Cairns</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Secure Angel Investors in Cairns: Professional Consulting Solutions</h1>
                                        <p>Welcome to Smart Resources QLD's Angel Investor Consulting services page for Cairns. As a hub for innovation in Far North Queensland, Cairns offers unique opportunities for startups seeking angel investment. Our expert team provides comprehensive consulting services to help you secure the funding you need to grow your business.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg good_investor_2">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Reach out today to learn how we can help you attract angel investors in Cairns!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={Angel1} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Angel Investor Consulting in Cairns?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Innovative Business Environment:    </strong>Cairns is known for its vibrant entrepreneurial community and innovative business ideas. Our consultants help you stand out in this competitive market.
                                                    </li>
                                                    <li>
                                                        <strong>Local Market Insights:    </strong> With deep knowledge of Cairns' business landscape, we provide tailored strategies to connect you with the right investors.
                                                    </li>
                                                    <li>
                                                        <strong>Extensive Network:  </strong>  We have a robust network of angel investors interested in funding promising startups in Cairns. Our connections can help you access crucial investment opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Strategic Development:  </strong> We assist in crafting compelling business plans and pitch presentations that attract investors and highlight your business's potential.
                                                    </li>
                                                    <li>
                                                        <strong>End-to-End Support:  </strong> From initial funding rounds to later stages of growth, our consulting services cover all aspects of the investment process, providing ongoing support to ensure your success.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Expert Consultants:   </strong>Our team of experienced consultants brings extensive knowledge in angel investment and startup development to your business.
                                                    </li>
                                                    <li>
                                                        <strong>Customized Approach:   </strong> We offer tailored consulting services designed to meet your specific needs, whether you're a new startup or an established business seeking additional funding.
                                                    </li>

                                                    <li>
                                                        <strong>Proven Success:  </strong> Our track record of successfully connecting startups with angel investors in Cairns demonstrates our ability to deliver results.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Centric Service:  </strong> We focus on your business goals and work collaboratively to develop strategies that align with your objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={WhyChhoseBg} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>



                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageCairns;