import React from 'react';
import Slider from 'react-slick';
import './Stlye.css'; // Make sure to import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ManImage from "./images/man1.webp";
import ManImage2 from "./images/man2.webp";
import woManImage1 from "./images/woman1.webp";
import woManImage2 from "./images/woman2.webp";
const TestimonialSection = () => {
  const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: false,
    autoplaySpeed: 3000, // Adjust the autoplay speed as needed
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial_sec">
    <div className="test_innr_sec">
      <div className="test_heading">
        <div className="test_heading_innr">
          <div className="test_heading_innr_left">
            <div className="about_left_heading">
              <div className="abt_sub_title test_sub_title">Testimonial</div>
              <h3 data-aos="fade-right">It's always a joy to hear that the work we do has positive reviews.</h3>
            </div>
          </div>
          <div className="test_heading_innr_right">
            <p>We have spent 5 years working for one of Australia's most recognized and successful retailers, so we have many good reviews of works.</p>
          </div>
        </div>
      </div>
      <div className="test_slick_carousel">
        <Slider {...settings}>
          <div className="test_slick_innr" data-aos="flip-left">
            <div className="item_des">
                
            <p>"Smart Resources QLD exceeded my expectations with their insightful advice and personalized approach. Their guidance was instrumental in achieving remarkable growth for my business."</p>
            </div>
            <div className="item--holder">
                        <div className="item--image">
                            <img decoding="async" className="" src={woManImage1} width="109" height="109" alt="testimonial-04" title="testimonial-04" />
                        </div>
                        <div className="item--meta">
                            <h3 className="item--title"> Emily Johnson</h3>
                            <div className="item--position">CEO of Sapphire Innovations Ltd</div>
                            <div className="item-rating"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></div></div></div>
            
          </div>
          <div className="test_slick_innr" data-aos="flip-left">
            <div className="item_des">
                
            <p>"I can't recommend Smart Resources QLD enough! Their expertise in mining consulting helped us streamline our operations and maximize profitability. Truly a game-changer for our company."</p>
            </div>
            <div className="item--holder">
                        <div className="item--image">
                            <img decoding="async" className="" src={ManImage} width="109" height="109" alt="testimonial-04" title="testimonial-04" />
                        </div>
                        <div className="item--meta">
                            <h3 className="item--title"> Jack Smith</h3>
                            <div className="item--position">Managing Director of Azure Ventures Inc</div>
                            <div className="item-rating"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></div></div></div>
            
          </div>
          <div className="test_slick_innr" data-aos="flip-left">
            <div className="item_des">
                
            <p>"Working with Smart Resources QLD was a game-changer for our real estate ventures. Their strategic insights and industry knowledge transformed our approach, leading to outstanding results."</p>
            </div>
            <div className="item--holder">
                        <div className="item--image">
                            <img decoding="async" className="" src={woManImage2} width="109" height="109" alt="testimonial-04" title="testimonial-04" />
                        </div>
                        <div className="item--meta">
                            <h3 className="item--title">Isabella Wong</h3>
                            <div className="item--position">President of Cobalt Enterprises Co.</div>
                            <div className="item-rating"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></div></div></div>
            
          </div>
          

          <div className="test_slick_innr" data-aos="flip-left">
            <div className="item_des">
                
            <p>"Smart Resources QLD provided invaluable data consulting services that revolutionized our decision-making processes. Their team's expertise and dedication made all the difference in our success."</p>
            </div>
            <div className="item--holder">
                        <div className="item--image">
                            <img decoding="async" className="" src={ManImage2} width="109" height="109" alt="testimonial-04" title="testimonial-04" />
                        </div>
                        <div className="item--meta">
                            <h3 className="item--title"> Liam Patel</h3>
                            <div className="item--position">CEO of Blue Illusion</div>
                            <div className="item-rating"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></div></div></div>
            
          </div>
        </Slider>
      </div>
    </div>
  </section>
  );
};

export default TestimonialSection;
