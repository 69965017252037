import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner3 from '../src/images/rockamphton_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
import Data226 from "../src/images/middle_img.webp";
// import Data224 from "../src/images/data_223.jpg";
// import Data225 from "../src/images/data_225.jpg";
import Data121348 from "../src/images/last_banner.webp";
import faq1 from '../src/images/faq_banner.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageRockhampton() {


    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'How can data science benefit the agriculture sector in Rockhampton?',
            answer: 'Data science can enhance agricultural practices through precision farming, optimizing irrigation, predicting crop yields, and managing livestock health, leading to increased productivity and sustainability.',
        },
        {
            question: 'What role does data science play in the mining industry in Rockhampton?',
            answer: "In the mining sector, data science improves operational efficiency, enhances safety protocols, predicts equipment maintenance needs, and optimizes resource extraction processes.",
        },
        {
            question: 'How does data science improve healthcare services in Rockhampton?',
            answer: "Data science enhances patient care by predicting disease outbreaks, personalizing treatment plans, optimizing resource allocation, and improving operational efficiency within healthcare facilities.",
        },
        {
            question: '. What types of data are useful for data science projects in Rockhampton?',
            answer: 'Useful data includes agricultural data, mining operation logs, healthcare records, educational performance data, customer feedback, and sales records. Our team helps identify and prepare the most relevant data for analysis.',
        },
        {
            question: 'How quickly can businesses in Rockhampton see results from data science initiatives?',
            answer: "The timeline varies depending on the project's scope and complexity, but many businesses begin to see valuable insights and improvements within a few months of implementation.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${Petroleumbanner3})`
            }}>
                 <MetaTags
        title="Data Science Service Rockhampton | Smart Resources QLD"
        description="Rockhampton businesses, unlock data-driven insights with Smart Resources QLD's expert data science services. Make smarter decisions across your operations."
        keywords="Data Science Service Rockhampton"
        identifyUrl="https://smartresourcesqld.com.au/data-science/rockhampton"
        DCTitle="Data Science Service Rockhampton | Smart Resources QLD"
        DCDescription="Rockhampton businesses, unlock data-driven insights with Smart Resources QLD's expert data science services. Make smarter decisions across your operations."
        DCSubject="Data Science Service Rockhampton"
        Canonical="https://smartresourcesqld.com.au/data-science/rockhampton"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Data Science Service Rockhampton | Smart Resources QLD"
        ogDescription="Rockhampton businesses, unlock data-driven insights with Smart Resources QLD's expert data science services. Make smarter decisions across your operations."
        ogUrl="https://smartresourcesqld.com.au/data-science/rockhampton"
      />

            <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
            </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Unlock the Potential of Data Science in Rockhampton</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/data-science">Data Science</NavLink></li>
                        <li>Rockhampton</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Transform Your Business with Data Science in Rockhampton </h1>
                                        <p>Welcome to Smart Resources QLD's Data Science services page for Rockhampton. As a key regional center in Central Queensland, Rockhampton is uniquely positioned to benefit from cutting-edge data science solutions. Our expert team is committed to helping local businesses and organizations harness the power of data to drive innovation, efficiency, and growth.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                        Discover how data science can transform your operations
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={Data226} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Benefits of Data Science for Rockhampton Businesses </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Optimized Agricultural Practices:   </strong>Use data-driven insights to enhance crop yield, manage livestock health, and improve resource utilization.
                                                    </li>
                                                    <li>
                                                        <strong>Increased Mining Efficiency:   </strong> Identify inefficiencies, optimize resource extraction, and predict equipment maintenance needs to minimize downtime.
                                                    </li>
                                                    <li>
                                                        <strong>Enhanced Decision Making:</strong> Leverage data-driven insights to make informed strategic decisions and stay competitive.
                                                    </li>
                                                    <li>
                                                        <strong>Operational Efficiency: </strong> Streamline processes, reduce costs, and enhance productivity through data analytics and automation.
                                                    </li>
                                                    <li>
                                                        <strong>Risk Management: </strong> Predict and mitigate potential risks by analyzing historical data and identifying emerging trends.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Data Science in Rockhampton? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Agriculture and Livestock:  </strong>Rockhampton, known as the Beef Capital of Australia, has a robust agricultural and livestock industry. Data science can revolutionize farming practices through precision agriculture, optimizing yield, and improving livestock management.
                                                    </li>
                                                    <li>
                                                        <strong>Mining and Resources: </strong> The mining sector in Rockhampton stands to gain significantly from data science by enhancing operational efficiency, improving safety, and predicting equipment failures.
                                                    </li>

                                                    <li>
                                                        <strong>Healthcare and Education: </strong> With growing healthcare and education sectors, data science can play a crucial role in improving patient care, streamlining operations, and enhancing educational outcomes through personalized learning and predictive analytics.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:</strong> Our team combines technical expertise with a deep understanding of Rockhampton's unique market dynamics, ensuring that our solutions are both innovative and locally relevant.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={Data121348} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>




                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageRockhampton;