import React from 'react';
import { NavLink } from 'react-router-dom';
import r from '../images/r.webp';
import m from '../images/mi.webp';
import pe from '../images/pe.webp';
import data from '../images/data-consulting.webp';
import angel from '../images/angel-banner.webp';
import bannerImage from '../images/about-banner.webp'; 
import {Row, Col} from "react-bootstrap";
import MetaTags from '../MetaTags';
const styles = {
  container: {
   
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  banner: {
    position: 'relative',
    backgroundColor: '#333',
    color: '#fff',
    // padding: '20px',
    width: '100%',
    zIndex:'1',
    textAlign: 'center',
    position: 'relative',
  },
  bannerOverlay: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.52)',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },


  bannerBlogHeading: {
    position: 'absolute',
    top:'50%',
    left: '50%',
    transform: 'translateX(-50%, -50%)',
    color:'#fff',
    fontSize: '40px',
  },
  bannerImage: {
    // maxWidth: '100%',
    // maxHeight: '300px', // Adjust the height as needed
    width:'100%',
    height:'420px',
    objectFit:'cover',
    objectPosition:'center',
  },
  itemContainer: {
    marginTop:"35px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // maxWidth: '100%',
    margin: '0 auto',
  },
  // itemRow: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   width: '100%',
  // },

  breadCrumb: {
    position: 'absolute',
    top: '60%',
    left:'50%',
    transform: 'translateX(-25%)',
  },
  item: {
    position: 'relative',
    // width: '296px',
    // height: '180px',
    backgroundColor: '#eee',
    border: '1px solid #ccc',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    textDecoration: 'none',
    color: '#333',
    transition: 'transform 0.3s ease',
    overflow: 'hidden', // Hide overflowing content
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    width: '100%',
    padding: '0 10px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
  },
  itemDetails: {
    fontSize: '14px',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(calc(-50% - 20px))', // Adjust position to accommodate title
    textAlign: 'center',
    width: '100%',
    padding: '0 10px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
  },
  itemImage: {
    width: '100%',
    height: '300px',
    transition: 'transform 0.3s ease',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  // Different styles for item1, item2, item3
  item123: {
    backgroundColor: '#f0f0f0',
    color: '#666',
  },
  // Different styles for item4
  item4: {
    backgroundColor: '#ddd',
    color: '#444',
    marginLeft: "150px"
  },
  // Different styles for item5
  item5: {
    backgroundColor: '#ccc',
    color: '#333',
    marginRight: "150px"
  },



 
};

const HeaderWithItems = () => {
 

  return (
    <div>
       <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        description="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        identifyUrl="https://smartresourcesqld.com.au/blog"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        DCDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        Canonical="https://smartresourcesqld.com.au/blog"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        ogDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors"
        ogUrl="https://smartresourcesqld.com.au/blog"
      />
      <div style={styles.banner} className='Banner_blog'>
        {/* <h1>Website Banner</h1> */}
        <img src={bannerImage} alt="Banner" style={styles.bannerImage} />
        <div style={styles.bannerOverlay}></div>
        <div style={styles.bannerBlogHeading}>Blog</div>
        <ul className="bread_crumb" style={styles.breadCrumb}><li><a href="/">Home</a></li><li>Blog</li></ul>
      </div>
      <div>
        <div >
          <div className='container'>
            <Row className='align-items-center justify-content-center my-5'>
            <Col lg="4" md="4" sm="6">
                <NavLink
                to="/post"
                style={{ ...styles.item, ...styles.item123 }}
                className="blogImage"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className='item_desp'>
                <div className="item-title" >Real Estate Consulting</div>
                <div className="item-details" >Navigating the Labyrinth: The Crucial Role Of Real Estate Consulting</div>
                </div>
                <img 
                  src={r} 
                  alt="Item 1" 
                  style={styles.itemImage} 
                  className="item-image" 
                />
                </NavLink>
            </Col>
            <Col lg="4" md="4" sm="6">
              <NavLink
                to="/miningpost "
                style={{ ...styles.item, ...styles.item123 }}
                className="blogImage"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className='item_desp'>
                <div className="item-title" >Mining Consulting</div>
                <div className="item-details" >Unearthing Opportunities: The Essential Role Of Mining Consulting</div>
                </div>
                <img 
                  src={pe} 
                  alt="Item 2" 
                  style={styles.itemImage} 
                  className="item-image" 
                />
              </NavLink>
            </Col>
            <Col lg="4" md="4" sm="6">
              <NavLink
                to="/petroleumpost"
                style={{ ...styles.item, ...styles.item123 }}
                className="blogImage"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className='item_desp'>
                <div className="item-title" >Petroleum Consulting</div>
                <div className="item-details" >Fueling Success: The Power Of Petroleum Consulting</div>
                </div>
                <img 
                  src={m} 
                  alt="Item 3" 
                  style={styles.itemImage} 
                  className="item-image"
                  onClick={() => window.scrollTo(0, 0)}
                />
              </NavLink>
            </Col>
            <Col lg="4" md="4" sm="6">
              <NavLink
                to="/datapost"
                style={{ ...styles.item, ...styles.item123 }}
                className="blogImage"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className='item_desp'>
                <div className="item-title" >Data Consulting</div>
                <div className="item-details" >Data Alchemy: Unveiling The Magic Of Data Consulting</div>
                </div>
                <img 
                  src={data} 
                  alt="Item 4" 
                  style={styles.itemImage} 
                  className="item-image" 
                />
              </NavLink>
            </Col>
            <Col lg="4" md="4" sm="6">
          <NavLink
            to="/fulepost "
            style={{ ...styles.item, ...styles.item123 }}
            className="blogImage"
            onClick={() => window.scrollTo(0, 0)}
          >
            <div className='item_desp'>
                <div className="item-title" >Angel Investors </div>
                <div className="item-details" >Wings of Opportunity: The Role Of Angel Investors In Fueling Innovation</div>
                </div>
            <img 
              src={angel} 
              alt="Item 5" 
              style={styles.itemImage} 
              className="item-image" 
            />
          </NavLink>
          </Col>
          </Row>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HeaderWithItems;