import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner2 from '../src/images/cairns_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
// import Data222 from "../src/images/data_222.jpg";
// import Data224 from "../src/images/data_223.jpg";
import Data225 from "../src/images/data_225.webp";
import Data121346 from "../src/images/data__121346.webp";
import MiningBannerer from "../src/images/mining_constulting_cairns.webp";
import faq1 from '../src/images/faq_banner.webp';
import Mining1213 from "../src/images/mining1213.webp";
import GoldMining from "../src/images/gold_mine_consulting.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageCairns() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why is Cairns a prime location for mining operations?',
            answer: 'Cairns` proximity to rich mineral deposits and its robust infrastructure make it an excellent base for mining activities in Far North Queensland.',
        },
        {
            question: 'How can your consulting services enhance my mining operations?',
            answer: "Our consulting services provide expert guidance, strategic planning, and operational support to optimize efficiency, sustainability, and profitability in your mining operations.",
        },
        {
            question: 'What mining projects do you specialize in Cairns?',
            answer: "We specialize in a variety of mining projects, including mineral exploration, extraction, and processing for diverse commodities.",
        },
        {
            question: 'How do you ensure that my mining operations comply with regulations?',
            answer: 'We stay current with all relevant regulations and work closely with you to ensure your operations meet all local, state, and federal compliance requirements.',
        },
        {
            question: 'What sustainable mining practices do you advocate?',
            answer: "We advocate for practices such as efficient resource utilization, waste reduction, and environmental impact mitigation to ensure responsible and sustainable mining operations.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${MiningBannerer})`,
                backgroundPosition: 'center'
            }}>
                 <MetaTags
        title="Mining Consulting Service in Cairns | Smart Resources QLD"
        description="Streamline your Cairns mining project with Smart Resources QLD's expert consulting. We offer tailored solutions for feasibility studies, permitting, and operational excellence."
        keywords="Mining Consulting Service in Cairns"
        identifyUrl="https://smartresourcesqld.com.au/mining-consulting/cairns"
        DCTitle="Mining Consulting Service in Cairns | Smart Resources QLD"
        DCDescription="Streamline your Cairns mining project with Smart Resources QLD's expert consulting. We offer tailored solutions for feasibility studies, permitting, and operational excellence."
        DCSubject="Mining Consulting Service in Cairns"
        Canonical="https://smartresourcesqld.com.au/mining-consulting/cairns"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Mining Consulting Service in Cairns | Smart Resources QLD"
        ogDescription="Streamline your Cairns mining project with Smart Resources QLD's expert consulting. We offer tailored solutions for feasibility studies, permitting, and operational excellence."
        ogUrl="https://smartresourcesqld.com.au/mining-consulting/cairns"
      />

        <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
        </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Mining Consulting in Cairns</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/mining-consulting">Mining Consulting</NavLink></li>
                        <li>Cairns</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Professional Mining Consulting Services in Cairns</h1>
                                        <p>Welcome to Smart Resources QLD's Mining Consulting services page for Cairns. As a prominent center for mining activities in Far North Queensland, Cairns offers unique opportunities for mining enterprises. Our expert team provides comprehensive consulting services to help you optimize your mining operations, ensuring they are efficient, sustainable, and compliant with regulations.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg mining_short_image">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                Reach out today to explore how we can support your mining ventures in Cairns!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={Mining1213} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Mining Consulting in Cairns?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Resource-Rich Region:    </strong>Cairns is located near some of the richest mineral deposits in Queensland, providing significant opportunities for mining operations. Our consultants can help you capitalize on these resources.
                                                    </li>
                                                    <li>
                                                        <strong>Local Market Insights:    </strong> With our deep understanding of the Cairns mining landscape, we offer insights and strategies that address the region's specific challenges and opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Sustainability Focus:  </strong>  We prioritize sustainable mining practices, helping you minimize environmental impact while maximizing resource extraction and operational efficiency.
                                                    </li>
                                                    <li>
                                                        <strong>Regulatory Navigation:  </strong> Our team ensures that your mining operations adhere to all relevant regulations, helping you avoid compliance issues and operational disruptions.
                                                    </li>
                                                    <li>
                                                        <strong>Innovative Solutions:  </strong> We employ the latest technologies and best practices in mining engineering and project management to deliver cutting-edge solutions.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services? </h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Expert Team:   </strong>Our team of experienced mining consultants brings extensive industry knowledge and local expertise to your projects.
                                                    </li>
                                                    <li>
                                                        <strong>Customized Approach:   </strong> We provide tailored consulting services designed to meet your unique needs, whether you're embarking on a new project or enhancing an existing one.
                                                    </li>

                                                    <li>
                                                        <strong>Track Record of Success:  </strong> Our proven track record in the Cairns mining sector demonstrates our ability to deliver successful outcomes and value to your operations.
                                                    </li>
                                                    <li>
                                                        <strong>Client-Centric Service:  </strong> We focus on your goals, working collaboratively to develop strategies that align with your business objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={GoldMining} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>



                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageCairns;