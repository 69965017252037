import React, { useEffect, useState } from 'react';
import './Stlye.css';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Petroleumbanner2 from '../src/images/cairns_bg.webp';
// import Petroleumbanner from '../src/images/data-banner.jpg';
// import DataCons1213 from "../src/images/data_cons_1213.jpg";
// import Data222 from "../src/images/data_222.jpg";
// import Data224 from "../src/images/data_223.jpg";
import Data225 from "../src/images/data_225.webp";
import Data121346 from "../src/images/data__121346.webp";
import faq1 from '../src/images/faq_banner.webp';
import RealCairns from "../src/images/real_estate_cons.webp";
import RealEstateWhyChooseCairns from "../src/images/real_estate_why_choose_cairns.webp";
import RealEstateOurServices from "../src/images/realestate_our_services.webp";
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import MetaTags from './MetaTags';
import { Helmet } from 'react-helmet-async';
function LandingPageCairns() {

    const [openIndex, setOpenIndex] = useState(null);
    const faqData = [
        {
            question: 'Why invest in Cairns real estate?',
            answer: 'Cairns is a growing city with a strong tourism sector, making it an attractive destination for real estate investment. The market offers potential for high returns on vacation rentals and commercial properties.',
        },
        {
            question: 'How do you evaluate investment properties in Cairns?',
            answer: "We assess investment properties based on location, market trends, property condition, and potential for rental income or resale value.",
        },
        {
            question: 'What are the key factors to consider when buying property in Cairns?',
            answer: "Key factors include proximity to amenities, property condition, market trends, and potential for appreciation or rental income.",
        },
        {
            question: 'Can you assist with finding vacation rental properties?',
            answer: 'Yes, we specialize in helping clients find profitable vacation rental properties in popular tourist areas of Cairns.',
        },
        {
            question: 'What are the advantages of using a real estate consultant in Cairns?',
            answer: "A real estate consultant offers expert market insights, negotiation expertise, and personalized service, ensuring that you make informed decisions and achieve your real estate goals.",
        },
        // Add more FAQ items as needed
    ];
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <section className="innr_banner" style={{
                backgroundImage: `url(${RealCairns})`, backgroundPosition: 'center',
            }}>
                 <MetaTags
        title="Real Estate Consulting Services in Cairns | Smart Resources QLD"
        description="Make smarter real estate decisions with Smart Resources QLD. Our experienced consultants offer a variety of services to help you succeed in the Cairns, Queensland market."
        keywords="Real Estate Consulting Services in Cairns"
        identifyUrl="https://smartresourcesqld.com.au/real-estate-consulting/cairns"
        DCTitle="Real Estate Consulting Services in Cairns | Smart Resources QLD"
        DCDescription="Make smarter real estate decisions with Smart Resources QLD. Our experienced consultants offer a variety of services to help you succeed in the Cairns, Queensland market."
        DCSubject="Real Estate Consulting Services in Cairns"
        Canonical="https://smartresourcesqld.com.au/real-estate-consulting/cairns"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate Consulting Services in Cairns | Smart Resources QLD"
        ogDescription="Make smarter real estate decisions with Smart Resources QLD. Our experienced consultants offer a variety of services to help you succeed in the Cairns, Queensland market."
        ogUrl="https://smartresourcesqld.com.au/real-estate-consulting/cairns"
      />

        <Helmet>
                <link rel="icon" href="../%PUBLIC_URL%/favicon.ico" />
        </Helmet>
                <div className="page_title_innr">
                    <div className="page_title">Real Estate Consulting in Cairns</div>
                    <ul className="bread_crumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/our-operations">Services</NavLink></li>
                        <li><NavLink to="/real-estate-consulting">Real Estate Consulting</NavLink></li>
                        <li>Cairns</li>
                    </ul>
                </div>
            </section>

            <section className='middle_partts'>
                <section className="pricing">
                    <div className="servinnng_middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="global_left serv_innr_heading">
                                        <h1>Professional Real Estate Consulting Services in Cairns</h1>
                                        <p>Welcome to Smart Resources QLD's Real Estate Consulting services page for Cairns. Known for its stunning natural beauty and thriving tourism industry, Cairns offers diverse real estate opportunities. Our team is here to help you navigate this unique market with expert advice and tailored solutions.</p>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="discuss-seo data_price_bg real_estate_bg">
                        <div className="container">
                            <div className="row discuss-ppc-seo">
                                <h2 style={{ color: 'white' }}>
                                    Reach out today to learn how we can assist you with your real estate ventures in Cairns!
                                </h2>
                                <div className="button-ppc-new">
                                    <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
                                    {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="include_services">
                        <div className='include_service_innr'>
                            <Container>
                                <Row>

                                    <Col lg="4" md="6" sm="12">
                                        <div className='include_img'><img src={RealEstateWhyChooseCairns} alt="" className="img-fluid" /></div>
                                    </Col>

                                    <Col lg="8" md="6" sm="12">
                                        <div className='include_text'>
                                            <h3>Why Choose Real Estate Consulting in Cairns?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Tourism and Investment Opportunities:    </strong>Cairns' booming tourism industry creates numerous opportunities for profitable real estate investments. Our consultants can help you identify and capitalize on these opportunities.
                                                    </li>
                                                    <li>
                                                        <strong>Local Expertise:    </strong> With extensive knowledge of Cairns' real estate market, we provide valuable insights into the best locations and emerging trends.
                                                    </li>
                                                    <li>
                                                        <strong>Customized Approach:  </strong>  We offer personalized consulting services to meet your specific needs, whether you're interested in residential properties, vacation rentals, or commercial investments.
                                                    </li>
                                                    <li>
                                                        <strong>End-to-End Support:  </strong> From initial market research to final negotiations and deal closures, we provide comprehensive support throughout your real estate journey.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>


                        </div>
                        <div className='include_service_innr'>
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='include_textpartts'>
                                        <div className='include_text'>
                                            <h3>Why Choose Our Services?</h3>
                                            {/* <img src={line} alt="Your Image" /> */}
                                            <div className='pf'>
                                                <ul>
                                                    <li>
                                                        <strong>Proven Expertise:   </strong>Our team has a proven track record of success in the Cairns real estate market, helping clients achieve their property goals.
                                                    </li>
                                                    <li>
                                                        <strong>Dedicated Support:   </strong>We are committed to providing exceptional customer service and dedicated support throughout the entire process.
                                                    </li>

                                                    <li>
                                                        <strong>Market Insight: </strong> We stay up-to-date with the latest market trends and insights to provide you with the most accurate and relevant information.
                                                    </li>
                                                    <li>
                                                        <strong>Strategic Approach: </strong> Our strategic approach ensures that your real estate investments are aligned with your long-term goals and objectives.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="4" md="6" sm="12" className='include_imgpartts'>
                                        <div className='include_img right'>
                                            <img src={RealEstateOurServices} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>



                        <div className="faqContainer">
                            <Container>
                                <Row>
                                    <Col lg="8" md="6" sm="12" className='accordion_left'>
                                        <div className="accordion">
                                            <h1>Frequently Asked Questions</h1>
                                            {faqData.map((item, index) => (
                                                <div key={index} className="accordion-item">
                                                    <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                                                        <h2>{item.question}</h2>
                                                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                                                    </div>
                                                    {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col lg="4" md="6" sm="12" className='accordion_right'>
                                        <div className="faq_Image">
                                            {/* Place your image here */}
                                            <div style={{ position: 'relative' }} className='faq_data'>
                                                <img
                                                    src={faq1}
                                                    className="img-fluid"
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                </section>
            </section>
        </>
    );
}

export default LandingPageCairns;