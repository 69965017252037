import React from 'react';
import '../Stlye.css'; // Make sure to import your CSS file
import { NavLink } from 'react-router-dom';
import theme04 from '../images/theme-04-541x600.webp'
import Miningconsulting from '../images/Mining-consulting.webp'
import dataconsulting from '../images/data-consulting.webp'
import angelinvestor from '../images/angel_investor1.webp'
import Petroleumconsulting from '../images/Petroleum-consulting.webp'
import MetaTags from '../MetaTags';
import bg from '../images/bg-page-title.webp'
function OurOperations() {
  return (
    <>
    <div>
    <MetaTags
        title="Innovation, Sustainability and Growth Solutions - Smart Resources QLD"
        description="Explore our sustainable mining and energy solutions at Smart Resources QLD. Discover innovative practices, safety standards, and community impact."
        keywords="Innovation, Sustainability and Growth Solutions"
        identifyUrl="https://smartresourcesqld.com.au/our-operations"
        DCTitle="Innovation, Sustainability and Growth Solutions - Smart Resources QLD"
        DCDescription="Explore our sustainable mining and energy solutions at Smart Resources QLD. Discover innovative practices, safety standards, and community impact."
        DCSubject="Innovation, Sustainability and Growth Solutions"
        Canonical="https://smartresourcesqld.com.au/our-operations"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Innovation, Sustainability and Growth Solutions - Smart Resources QLD"
        ogDescription="Explore our sustainable mining and energy solutions at Smart Resources QLD. Discover innovative practices, safety standards, and community impact."
        ogUrl="https://smartresourcesqld.com.au/our-operations"
      />
        <section className="innr_banner"  style={{ 
  backgroundImage: `url(${bg})` }}>
          <div className="page_title_innr">
            <div className="page_title">Our Operations</div>
            <ul className="bread_crumb">
              <li><NavLink to="/">Home</NavLink></li>
              <li>Services</li>
            </ul>
          </div>
        </section>
        <section className="pricing">
        <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="global_left">
                        <div className="global_label_top">
                            Our Operations 
                        </div>
                        <h1> We position our clients at the forefront of their field by advancing an agenda </h1>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="global_right pt-5">
                            <p>  Explore our comprehensive range of services designed to meet your business needs and drive success across various industries. From real estate consulting to data analysis, our diverse expertise ensures tailored solutions for every aspect of your operations. Partner with us to unlock new opportunities and achieve sustainable growth for your business.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="realestate operations grid-item-inner wow fadeIn">
                            <label className="grid-item-over">
                                <div className="item--featured"> 
                                    <img className="disable-lazy no-lazyload " src= {theme04} width="98%" height="410" alt="theme-04" title="theme-04"/>
                                </div>
                                <div className="real_estate_consulting item--holder1">
                                    <h4>Real Estate Consulting</h4>
                                    <div className="item--gap"></div>
                                    <p>At Smart Resources Qld, our real estate consulting services offer several advantages to clients seeking to navigate the complexities of the property market. With our extensive industry knowledge and market expertise, we provide tailored solutions that meet the unique needs of each client. Our advantages include access to a wide network of industry contacts, in-depth market analysis, and strategic insights that help clients make informed decisions and capitalize on opportunities.</p>
                                </div>

                                <div className="item--meta1">
                                    <h3 className="item--title1"> 
                                        <NavLink to="/real-estate-consulting" onClick={() => window.scrollTo(0, 0)}>Real Estate Consulting</NavLink></h3>
                                        <div className="item--readmore"> 
                                            <NavLink className="more-plus" onClick={() => window.scrollTo(0, 0)} to="/real-estate-consulting">+</NavLink>
                                        </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="realestate operations grid-item-inner wow fadeIn">
                            <label className="grid-item-over">
                                <div className="item--featured"> 
                                    <img className="disable-lazy no-lazyload " src= {Miningconsulting}width="98%" height="410" alt=''/>
                                </div>
                                <div className="real_estate_consulting item--holder1">
                                    <h4>Mining Consulting</h4>
                                    <div className="item--gap"></div>
                                    <p>As a premier mining consulting firm, Smart Resources Qld offers a range of advantages to clients operating in the mining industry. With our deep understanding of the sector and technical expertise, we provide innovative solutions that address the unique challenges faced by mining companies. Our advantages include access to cutting-edge technologies, proven methodologies, and a team of experienced professionals who can deliver results in even the most challenging environments.</p>
                                </div>

                                <div className="item--meta1">
                                    <h3 className="item--title1"> 
                                        <NavLink to="/mining-consulting" onClick={() => window.scrollTo(0, 0)}>Mining Consulting</NavLink></h3>
                                        <div className="item--readmore"> 
                                            <NavLink className="more-plus" onClick={() => window.scrollTo(0, 0)} to="/mining-consulting">+</NavLink>
                                        </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="realestate operations grid-item-inner wow fadeIn">
                            <label className="grid-item-over">
                                <div className="item--featured"> 
                                    <img className="disable-lazy no-lazyload " src={Petroleumconsulting} width="98%" height="410" alt=''/>
                                </div>
                                <div className="real_estate_consulting item--holder1">
                                    <h4>Petroleum Consulting</h4>
                                    <div className="item--gap"></div>
                                    <p>Smart Resources Qld is a leading provider of petroleum consulting services, offering several advantages to clients in the oil and gas industry. With our extensive industry experience and global network of experts, we provide strategic guidance and technical expertise that help clients optimize performance, minimize risks, and capitalize on opportunities. Our advantages include access to proprietary data and market intelligence, innovative solutions tailored to the specific needs of each client, and a commitment to delivering results that drive value and enhance competitiveness in the marketplace.</p>
                                </div>

                                <div className="item--meta1">
                                    <h3 className="item--title1"> 
                                        <NavLink to="/petroleum-consulting" onClick={() => window.scrollTo(0, 0)}>Petroleum Consulting</NavLink></h3>
                                        <div className="item--readmore"> 
                                            <NavLink className="more-plus" onClick={() => window.scrollTo(0, 0)} to="/petroleum-consulting">+</NavLink>
                                        </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="realestate operations grid-item-inner wow fadeIn">
                            <label className="grid-item-over">
                                <div className="item--featured"> 
                                    <img className="disable-lazy no-lazyload " src= {dataconsulting} width="98%" height="410" alt=''/>
                                </div>
                                <div className="real_estate_consulting item--holder1">
                                    <h4>Data Science</h4>
                                    <div className="item--gap"></div>
                                    <p>At Smart Resources Qld, our data science offer several advantages to organizations seeking to harness the power of data to drive innovation and achieve business success. With our expertise in data science, machine learning, and artificial intelligence, we help clients unlock valuable insights from their data that inform strategic decision-making and improve operational efficiency. Our advantages include access to advanced technologies and tools, a team of skilled data scientists and analysts, and a proven track record of delivering measurable results.</p>
                                </div>

                                <div className="item--meta1">
                                    <h3 className="item--title1"> 
                                        <NavLink to="/data-science" onClick={() => window.scrollTo(0, 0)}>Data Science</NavLink></h3>
                                        <div className="item--readmore"> 
                                            <NavLink className="more-plus" onClick={() => window.scrollTo(0, 0)} to="/data-science">+</NavLink>
                                        </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="realestate operations grid-item-inner wow fadeIn">
                            <label className="grid-item-over">
                                <div className="item--featured"> 
                                    <img className="disable-lazy no-lazyload " src={angelinvestor} width="98%" height="410" alt=''/>
                                </div>
                                <div className="real_estate_consulting item--holder1">
                                    <h4>Angel Investor</h4>
                                    <div className="item--gap"></div>
                                    <p>Are you an entrepreneur with a groundbreaking idea, seeking the fuel to propel it to success? Or perhaps you're an investor looking to support innovation while seeking lucrative opportunities? At [Your Angel Investor Service Name], we bridge the gap between visionary entrepreneurs and savvy investors, fostering a thriving ecosystem of innovation and growth.</p>
                                </div>

                                <div className="item--meta1">
                                    <h3 className="item--title1"> 
                                        <NavLink to="/angel-investor" onClick={() => window.scrollTo(0, 0)}>Angel Investor</NavLink></h3>
                                        <div className="item--readmore"> 
                                            <NavLink className="more-plus" to="/angel-investor" onClick={() => window.scrollTo(0, 0)}>+</NavLink>
                                        </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer className="footer">
          {/* Footer content */}
        </footer>
      </div>
    </>
  );
}

export default OurOperations;
