// Navbar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from "./images/smartresourceslogo1.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  // const [isIconMinus, setIsIconMinus] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleClass = () => {
    setIsActive(!isActive);
    
  };

  const toggleClass1 = () => {
    setIsActive1(!isActive1);
    
  };
  // const toggleSubmenu = () => {
  //   setIsOpen1(!isOpen1);
  //   setIsIconMinus(!isIconMinus);
  // };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 991) {
        setOpenSubMenu(null); // Close sub-menu when window width is greater than or equal to 991px
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const handleToggleSubMenu = (index) => {
  //   setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index));
  // };
  return (
    <header className="innr_header">
      <nav className=  {isSticky ? 'navbar navbar-expand-lg sticky' : 'navbar navbar-expand-lg'}>
        <div className="container d-flex justify-content-between">
          <NavLink className="navbar-brand" to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={Logo} alt="Smart Resources Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleClass}
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <div className={isActive ? "collapse_menu active" : "collapse_menu"}>
          <div className="d-lg-none d-md-block mb-5">
           
          </div>
          <span className='cross_butt' onClick={toggleClass}>X</span>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink exact="exact" onClick={() => window.scrollTo(0, 0)} className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/about-us">About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={`nav-link has-sub-menu ${isOpen1 ? 'active' : ''}`} to="/our-operations"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={(e) => { toggleMenu(); window.scrollTo(0, 0); }}>Our Operations</NavLink>
                <span id="icon" className="ct-menu-toggle" onClick={toggleVisibility}><FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} /></span>
                <ul className={`submenu ${isVisible ? 'visible' : 'hidden'}`}>
        <li>
          <NavLink className="dropdown-item" to="/real-estate-consulting" onClick={() => window.scrollTo(0, 0)}>
            Real Estate Consulting
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/mining-consulting" onClick={() => window.scrollTo(0, 0)}>
            Mining Consulting
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/petroleum-consulting" onClick={() => window.scrollTo(0, 0)}>
            Petroleum Consulting
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/data-science" onClick={() => window.scrollTo(0, 0)}>
            Data Science
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/angel-investor" onClick={() => window.scrollTo(0, 0)}>
            Angel Investor
          </NavLink>
        </li>
      </ul>
              </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink>
            </li>
            
            {/* Add other nav links here */}
          </ul>
        </div>
        </div>

        
      </nav>
    </header>
  );
};

export default Navbar;
