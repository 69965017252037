
import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUsSection from './AboutUsSection';
import FunFactSection from './FunFactSection';
import ServicesSection from './ServicesSection';
import TestimonialSection from './TestimonialSection';
import WhyChooseSection from './WhyChoose';
import OurProjectSection from './OurProjectSection'; 
import { HelmetProvider } from 'react-helmet-async';
import Angelinvestor from "./Services/Angelinvestor";
import Header from './Header';
import Footer from './Footer';
import NewsFeed from './NewsFeed';
import ContactUs from './ContactUs';
import Aboutus from './About-us';
import Contact from './Contact-us';
import OurOperations from './Services/OurOperations';
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';

import Realestateconsulting from './Services/Realestateconsulting';
import PetroleumConsultingPage from './Services/PetroleumConsultingPage'
import MetaTags from "./MetaTags";
import Dataconsulting from './Services/Dataconsulting';
import Miningconsulting from './Services/Miningconsulting ';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogPost from './Blog/BlogPost';
import LandingPageTownsVille from "./LandingPageTownsVille";
import LandingPageTownsVilleAngelInvestor from "./LandingPageTownsVilleAngelInvestor";
import LandingPageTownsVilleMiningConsulting from "./LandingPageTownsVilleMiningConsulting";
import LandingPageTownsVillePetroleumConsulting from "./LandingPageTownsVillePetroleumConsulting";
import LandingPageTownsVilleRealEstateConsulting from "./LandingPageTownsVilleRealEstateConsulting";
import LandingPageCairns from "./LandingPageCairns";
import LandingPageCairnsAngelInvestor from './LandingPageCairnsAngelInvestor';
import LandingPageCairnsMiningConsulting from './LandingPageCairnsMiningConsulting';
import LandingPageCairnsPetroleumConsulting from './LandingPageCairnsPetroleumConsulting';
import LandingPageCairnsRealEstateConstulting from './LandingPageCairnsRealEstateConsulting';
import LandingPageRockhampton from "./LandingPageRockhampton";
import LandingPageRockhamptonAngelInvestor from "./LandingPageRockhamptonAngelInvestor";
import LandingPageRockhamptonMiningConsulting from "./LandingPageRockhamptonMiningConsulting";
import LandingPageRockhamptonPetroleumConstulting from "./LandingPageRockhamptonPetroleumConsulting";
import LandingPageRockhamptonRealEstateConsulting from './LandingPageRockhamptonRealEstateConsulting';
// import LandingPageRockhampton from "./LandingPageRockhampton";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeaderWithItems  from './Blog/Home'
import FuelingPost from './Blog/FuelingPost';
import MiningPost from './Blog/MiningPost';
import PetroleumPost from './Blog/PetroleumPost';
import DataPost from './Blog/DataPost';
import OnHover from "./onHover";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  const [progressBarsAnimated, setProgressBarsAnimated] = useState(false);

  useEffect(() => {
    $(document).ready(() => {
      const sectionE = $('.consultio_content .banner_bottom_partts').offset()?.top;
      const sectionF = $('.consultio_content .about_us_section').offset()?.top;
      const sectionG = $('.consultio_content .funfact_partts').offset()?.top;
      const sectionL = $('.consultio_content .testimonial_sec').offset()?.top;
      const sectionH = $('.consultio_content .why_choose').offset()?.top;
      const sectionI = $('.consultio_content .services').offset()?.top;
      const sectionK = $('.consultio_content .our_prj').offset()?.top;
      const scrollOffset = 320;

      const handleScroll = () => {
        const scroll = $(window).scrollTop() + scrollOffset;

        if (scroll > sectionE) {
          $('.banner_bottom_innr_partts').addClass('flip_in');
        }
        // Add other scroll-related logic here...
      };

      $(window).scroll(handleScroll);

      return () => {
        $(window).off('scroll', handleScroll);
      };
    });
  }, [progressBarsAnimated]);
  return (
   <>
   
   <HelmetProvider>
   
      <BrowserRouter>

<Routes>

 

  <Route
    path="/" element={<>
    <Navbar/><Header/><AboutUsSection/>   <FunFactSection/>
    <WhyChooseSection/>
    <ServicesSection/>
 
    <TestimonialSection/>
    {/* <OurProjectSection/> */}
    <NewsFeed/>
    <ContactUs/>
    <Footer/>
    
    </>}
    
  />
  
  <Route
    path="/about-us" element={<> <Navbar/><Aboutus/>
     <Footer/>
    </>}

  />
    
    <Route
    path="/contact-us" element={<> <Navbar/><Contact/>  <Footer/>
   
    </>}

  />
   <Route
    path="/our-operations" element={<><Navbar/> <OurOperations/>  <Footer/>
   
    </>}

  />
  <Route
    path="/real-estate-consulting" element={<><Navbar/> <Realestateconsulting/>  <Footer/>
   
    </>}

  />
  <Route
    path="/petroleum-consulting" element={<><Navbar/> <PetroleumConsultingPage/>  <Footer/>
   
    </>}

  />
  <Route
            path="/data-science" element={<><Navbar/> <Dataconsulting/>  <Footer/>
   
            </>}
            

          />
          <Route
            path="/mining-consulting" element={<><Navbar /> <Miningconsulting />  <Footer />

            </>}


          />

<Route
            path="/angel-investor" element={<><Navbar /> <Angelinvestor />  <Footer />

            </>}


          />
          <Route
            path="/blog" element={<><Navbar /> <HeaderWithItems />  <Footer />

            </>}


          />
       <Route
            path="/post" element={<><Navbar /> <BlogPost />  <Footer />

            </>}


          />
        <Route
            path="/datapost" element={<><Navbar /> <DataPost />  <Footer />

            </>}


          />
           <Route
            path="/fulepost" element={<><Navbar /> <FuelingPost />  <Footer />

            </>}


          />
           <Route
            path="/miningpost" element={<><Navbar /> <MiningPost />  <Footer />

            </>}


          />
           <Route
            path="/petroleumpost" element={<><Navbar /> <PetroleumPost />  <Footer />

            </>}


          


          />
          <Route
            path="/data-science/townsville" element={<><Navbar /> <LandingPageTownsVille />  <Footer />

            </>}
          />
          <Route
            path="/angel-investor/townsville" element={<><Navbar /> <LandingPageTownsVilleAngelInvestor />  <Footer />

            </>}
          />
          <Route
            path="/mining-consulting/townsville" element={<><Navbar /> <LandingPageTownsVilleMiningConsulting />  <Footer />

            </>}
          />
          <Route
            path="/petroleum-consulting/townsville" element={<><Navbar /> <LandingPageTownsVillePetroleumConsulting />  <Footer />

            </>}
          />
          <Route
            path="/realestate-consulting/townsville" element={<><Navbar /> <LandingPageTownsVilleRealEstateConsulting />  <Footer />

            </>}
          />
        <Route
            path="/data-science/cairns" element={<><Navbar /> <LandingPageCairns />  <Footer />

            </>}
          />
          <Route
            path="/angel-investor/cairns" element={<><Navbar /> <LandingPageCairnsAngelInvestor />  <Footer />

            </>}
          />

          <Route
            path="/mining-consulting/cairns" element={<><Navbar /> <LandingPageCairnsMiningConsulting />  <Footer />

            </>}
          />
          <Route
            path="/petroleum-consulting/cairns" element={<><Navbar /> <LandingPageCairnsPetroleumConsulting />  <Footer />

            </>}
          />
          <Route
            path="/realestate-consulting/cairns" element={<><Navbar /> <LandingPageCairnsRealEstateConstulting />  <Footer />

            </>}
          />
          <Route
            path="/data-science/rockhampton" element={<><Navbar /> <LandingPageRockhampton />  <Footer />

            </>}
          />

          <Route
            path="/angel-investor/rockhampton" element={<><Navbar /> <LandingPageRockhamptonAngelInvestor />  <Footer />

            </>}
          />

          <Route
            path="/mining-consulting/rockhampton" element={<><Navbar /> <LandingPageRockhamptonMiningConsulting />  <Footer />

            </>}
          />
          <Route
            path="/petroleum-consulting/rockhampton" element={<><Navbar /> <LandingPageRockhamptonPetroleumConstulting />  <Footer />

            </>}
          />
          <Route
            path="/realestate-consulting/rockhampton" element={<><Navbar /> <LandingPageRockhamptonRealEstateConsulting />  <Footer />

            </>}
          />
          <Route
            path="/on-hover" element={<><Navbar /> <OnHover />  <Footer />

            </>}
          />
        </Routes>

</BrowserRouter>

</HelmetProvider>
   </>
  );
}

export default App;
